import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, IPublicClientApplication, InteractionStatus, InteractionType, PopupRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject, catchError, filter, map, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { BackendService } from './data/backend.service';
import { appFunctions } from '../app.model';


// MSAL Factories
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(environment.msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(environment.apiConfig.url, environment.apiConfig.scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: environment.msalGuardConfig.authRequest
  };
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _destroying$ = new Subject<void>();

  isReady = this.isReady$.asObservable();
  isAuthorized?: boolean;
  appFunctionsEnabled: string[] = [];

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private backendService: BackendService) {

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      const accounts = this.msalService.instance.getAllAccounts();
      if (accounts.length > 0) {

        if (!this.msalService.instance.getActiveAccount()) {
          this.msalService.instance.setActiveAccount(accounts[0]);
        }

        this.backendService.httpGet<{fullname: string, username: string, functionalities: string[]}>('Users/GetUserInfo').pipe(
          map(x => {
            this.appFunctionsEnabled = x.functionalities;
            this.isAuthorized = (this.appFunctionsEnabled.length > 0);
            this.isReady$.next(true);
          }),
          catchError(error => {
            this.isAuthorized = (error.status == '401') ? false : undefined;
            this.isReady$.next(true);
            throw error;
          })
        ).subscribe();

      } else {

        this.login().subscribe();

      }
    });
  }

  getAccountName(): string | undefined {
    return this.msalService.instance.getActiveAccount()?.name;
  }

  public isEnabled(appFunction: appFunctions): boolean {
    return this.appFunctionsEnabled.includes(appFunction);
  }

  login(): Observable<void> {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      return this.loginWithPopup()
                 .pipe(map(() => {
                   //this.isReady$.next(true);
                  }));
    } else {
      return this.loginWithRedirect()
                 .pipe(map(() => {
                   //this.isReady$.next(true);
                  }));
    }
  }

  private loginWithPopup(): Observable<void> {
    if (this.msalGuardConfig.authRequest) {
      return this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .pipe(map((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        }));
    } else {
      return this.msalService.loginPopup()
        .pipe(map((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        }));
    }
  }

  private loginWithRedirect(): Observable<void> {
    if (this.msalGuardConfig.authRequest) {
      return this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      return this.msalService.loginRedirect();
    }
  }

  // logout() {
  //   return this.msalService.logout().pipe(map(() => { this.isReady$.next(false) }));
  // }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
