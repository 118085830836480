<div class="flex justify-center pt-8 w-full h-full">
  @if (isReadOnly) {

    <app-table
      class="max-w-full max-h-full"
      [columnDefs]="columnDefs"
      [data]="data">
    </app-table>

  } @else {

    <app-table #table
      class="max-w-full max-h-full"
      [columnDefs]="columnDefs"
      [data]="data"
      idField="id"
      (cellClick)="onTableRowClick($event)">
    </app-table>

  }
</div>

<app-dialog
  [title]="edit.parameter"
  [buttons]="['Cancel', 'Ok']"
  [(visible)]="edit.visible"
  (buttonClick)="onEditClick($event)">

  <app-input-number
    label="Threshold (€)"
    [(value)]="edit.value">
  </app-input-number>

</app-dialog>
