import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { HomeComponent } from './main/pages/home/home.component';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [HomeComponent],
  templateUrl: './app.component.html',
  styles: [],
})
export class AppComponent implements OnInit, OnDestroy {

  isReady = false;
  isAuthorized?: boolean;
  intervalId: NodeJS.Timeout;

  constructor(private authService: AuthService) {
    // per qualche strano motivo non parte la change detection dopo che isReady viene messo a true, funziona solo così
    this.intervalId = setInterval(() => {
      if (this.isReady) {
        clearInterval(this.intervalId);
      }
    }, 500);

  }

   ngOnInit(): void {
      this.authService.isReady.subscribe(x => {
        this.isReady = x;
        this.isAuthorized = this.authService.isAuthorized;
      });
    }

    ngOnDestroy(): void {
      clearInterval(this.intervalId);
    }
}
