import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IconComponent } from "../icon/icon.component";
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
    selector: 'app-menu',
    standalone: true,
    templateUrl: './menu.component.html',
    styles: ``,
    imports: [DropdownComponent, IconComponent]
})
export class MenuComponent implements OnInit {
  @Input() items: ({
    key: string,
    value: string,
    icon?: string,
    disabled?: boolean
  } | null)[] = [];
  @Output() itemClick = new EventEmitter<string>();

  @ViewChild('button') button!: ElementRef;
  keepFocus: boolean = false;
  showMenu: boolean = false;
  itemsWithIcon: boolean = false;

  ngOnInit(): void {
    this.itemsWithIcon = this.items.some(x => x && x.icon != undefined);
  }

  onButtonClick() {
    this.showMenu = !this.showMenu;
  }

  onButtonFocus() {
    this.keepFocus = true;
  }

  onButtonBlur() {
    this.keepFocus = false;
  }

  onClick() {
    this.keepFocus = true;
    this.button.nativeElement.focus();  // rimetto il focus sul bottone
  }

  onItemClick(key: string) {
    this.showMenu = false;
    this.itemClick.emit(key);
  }

}
