
<div class="grid grid-cols-[14rem_1fr] grid-rows-[2.2rem_1fr] h-[100vh] text-gray-800 overflow-hidden">

  <!-- sidebar -->
  <div class="row-span-2 px-2 py-1">
    <div class="flex justify-center mb-8">
      <app-icon class="hover:scale-105" name="Costa-logo"></app-icon>
    </div>

    @for (section of menu; track $index) {
      @if ($index > 0) {
        <div class="mt-3 ml-1 mr-3 border-t border-gray-200"></div>
      }

      <div class="flex mt-3 ml-2 mb-2 items-center text-blue-900">
        <app-icon [name]="section.icon"></app-icon>
        <div class="ml-2 font-medium">{{section.section}}</div>
      </div>

      @for (page of section.pages; track $index) {
        @if (authService.isEnabled(page.function)) {
          <div class="pl-3 mr-2 py-1 my-[-4px] border-l-[3px] hover:cursor-pointer hover:text-gray-900 hover:bg-gray-100"
            [ngClass]="{ 'border-blue-900 rounded-r': page.route == currentPage.route, 'border-transparent rounded': page.route != currentPage.route}"
            (click)="onMenuClick(page)">
            {{page.label}}
          </div>
        } @else {
          <div class="pl-3 mr-2 py-1 my-[-4px] border-l-[3px] text-gray-400 border-transparent rounded">
            {{page.label}}
          </div>
        }

      }
    }
  </div>

  <!-- header -->
  <div class="flex px-4 py-1">
    <div class="flex flex-auto justify-center text-lg pr-32">
      <div class="font-medium">AML Collector</div>
      <div class="font-normal">&nbsp;&nbsp;-&nbsp;&nbsp;{{currentPage.label}}</div>
    </div>
    <div class="flex-initial items-end text-right flex">
      <app-icon name="user" class="pb-1"></app-icon>
      <div class="ml-1 mr-2">{{accountName}}</div>
    </div>
  </div>

  <!-- content -->
  <div class="bg-slate-50 px-4 py-2 rounded-ss-xl w-[calc(100vw-13.5rem)] h-[calc(100vh-2.2rem)] border-t-2 border-slate-100 shadow-lg">
    <router-outlet></router-outlet>
  </div>
</div>

<app-message-outlet></app-message-outlet>
