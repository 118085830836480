export enum appFunctions {
  Dashboard_GetCounts = 'Dashboard_GetCounts',
  PEPApprovals_Search = 'PEPApprovals_Search',
  SuspiciousTransactions_Search = 'SuspiciousTransactions_Search',
  Paxes_Search = 'Paxes_Search',
  DueDiligence_Search = 'DueDiligence_Search',
  Settings_DueDiligence = 'Settings_DueDiligence',
  Countries_Search = 'Countries_Search',
  Settings_RiskScore = 'Settings_RiskScore',
  Dashboard_GetCounts_PaxAdded = 'Dashboard_GetCounts_PaxAdded',
  Dashboard_GetCounts_PaxUIF = 'Dashboard_GetCounts_PaxUIF',
  Dashboard_GetCounts_PaxDueDiligence = 'Dashboard_GetCounts_PaxDueDiligence',
  PEPApprovals_MassiveUpdate_Approve = 'PEPApprovals_MassiveUpdate_Approve',
  PEPApprovals_MassiveUpdate_NotApprove = 'PEPApprovals_MassiveUpdate_NotApprove',
  PEPApprovals_Export = 'PEPApprovals_Export',
  SuspiciousTransactions_MassiveUpdate_UIF = 'SuspiciousTransactions_MassiveUpdate_UIF',
  Paxes_MassiveUpdate_VCSend = 'Paxes_MassiveUpdate_VCSend',
  Paxes_Report = 'Paxes_Report',
  Paxes_Export = 'Paxes_Export',
  DueDiligence_Report = 'DueDiligence_Report',
  Settings_Update_DueDiligence = 'Settings_Update_DueDiligence',
  Countries_Update = 'Countries_Update',
  Countries_Export = 'Countries_Export',
  Settings_Update_RiskScore = 'Settings_Update_RiskScore',
  Paxes_MassiveUpdate_UIF = 'Paxes_MassiveUpdate_UIF',
  Paxes_MassiveUpdate_VCFeedback = 'Paxes_MassiveUpdate_VCFeedback',
  Paxes_MassiveUpdate_Notes = 'Paxes_MassiveUpdate_Notes',
  Paxes_Document = 'Paxes_Document'
}
