import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ButtonComponent } from "../button/button.component";

@Component({
    selector: 'app-dialog',
    standalone: true,
    templateUrl: './dialog.component.html',
    styles: ``,
    imports: [ButtonComponent]
})
export class DialogComponent implements OnChanges {
  @Input() buttons: string[] = ['Ok'];
  @Input() buttonsDisabled: boolean[] = [false];
  @Input() title: string = '';
  @Input() visible: boolean = false;
  @Output() buttonClick = new EventEmitter<number>();
  @Output() visibleChange = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['buttons']) {
      this.buttonsDisabled = this.buttons.map(() => false);
    }
  }

  onDismiss() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  onButtonClick(index: number) {
    this.buttonClick.emit(index);
    this.onDismiss();
  }
}
