<div class="flex flex-col px-1 bg-white shadow rounded-lg">

  <div class="h-3 pb-0.5 flex items-end justify-center text-gray-400">
    <svg height="6" width="10">
      <polygon points="0,6 5,0 10,6" style="fill:currentColor" />
    </svg>
  </div>
  @for (item of items; track $index) {
    @if (item == null) {
      <div class="divide-y divide-gray-300">
        <div class="h-2"></div>
        <div class="h-2"></div>
      </div>
    } @else {
      <div
        class="px-4 cursor-pointer rounded-sm hover:bg-gray-100"
        [ngClass]="{ 'font-semibold': item.key == selectedKey }"
        (click)="onClick(item.key)">

        {{item.value}}
      </div>
    }
  }

  <div class="h-3 pt-0.5 flex items-start justify-center text-gray-400">
    <svg height="6" width="10">
      <polygon points="0,0 5,6 10,0" style="fill:currentColor" />
    </svg>
  </div>
</div>
