import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageService, Message } from './message.service';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';


@Component({
  selector: 'app-message-outlet',
  standalone: true,
  imports: [DialogComponent],
  templateUrl: './message-outlet.component.html',
  styles: ``
})
export class MessageOutletComponent implements OnInit{

  message!: Message;
  formattedText!: string[][];
  dialogButtons: string[] = [];
  visible: boolean = false;
  isDelayOver: boolean = false;
  currentTimeout?: ReturnType<typeof setTimeout>;

  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    this.messageService.messages.subscribe(x => {
      if (this.currentTimeout) { // interrompo un precedente messaggio
        clearTimeout(this.currentTimeout);
      }

      this.message = x;
      if (this.message.type == 'MessageWaiting' && this.message.event == 'End') {
        this.visible = false;
      } else {
        this.visible = true;
      }

      switch (this.message.type) {
        case 'MessageAlert':
          this.isDelayOver = false;
          setTimeout(() => { this.isDelayOver = true; }, 2000);

          this.currentTimeout = setTimeout(() => {
            this.visible = false;
            this.isDelayOver = false;
            this.currentTimeout = undefined;
          }, 3500);
          break;

        case 'MessageConfirm':
          if (this.message.buttons) {
            this.dialogButtons =   this.message.buttons?.map(x => x[0]);
          } else {
            this.dialogButtons = ['Ok'];
          }
          this.formattedText = this.formatText(this.message.text);
          break;
      }
    });
  }

  onButtonClick(index: number) {
    if (this.message.type == 'MessageConfirm' && this.message.buttons && this.message.buttons.length > 0) {
      const callbackFn = this.message.buttons[index][1];
      if (callbackFn != null) {
        setTimeout(callbackFn, 100);
      }
    }
  }

  private formatText(text: string): string[][] {
    const result = Array<string[]>(0);

    for (const row of text.split('\\n')) {    // righe
      let pieces = Array<string>(0);
      let odd = true;
      for (const piece of row.split('\\b')) {    // bold
        pieces.push(odd ? piece : '\\b' + piece);
        odd = !odd;
      }
      result.push(pieces);
    }

    return result;
  }

}
