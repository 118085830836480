import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MainModule } from '../../main.module';
import { BasePage } from '../base-page';
import { FilterDef, KeyValue, PaxDrillDown } from '../../../services/data/data.model';
import { PaxPopupComponent } from '../pax-popup/pax-popup.component';
import { Observable, forkJoin, map } from 'rxjs';
import { ColumnDef, TableComponent } from '../../../shared/components/table/table.component';
import { appFunctions } from '../../../app.model';

@Component({
  selector: 'app-pax-drilldown',
  standalone: true,
  imports: [MainModule, PaxPopupComponent],
  templateUrl: './pax-drilldown.component.html',
  styles: ``
})
export class PaxDrilldownComponent extends BasePage implements OnInit {
  constructor(private injector: Injector) { super(injector, 'pax-drilldown'); }

  @ViewChild('table') table!: TableComponent;
  isReadOnly = !this.authService.isEnabled(appFunctions.Paxes_MassiveUpdate_VCSend);

  paxPopup = {id: '', name: '', surname: '', visible: false};
  fromDate!: Date;
  toDate!: Date;

  checkedIds: string[] = [];
  tableFilters: string[] = [];
  scoreStatus: KeyValue[] = [];
  operationStatus: KeyValue[] = [];
  selectedScoreStatus = '';
  selectedOperationStatus = '';

  private allFilters: FilterDef[] = [];
  numChecked: number = 0;

  menuItems = [
    { key: '1', value: 'Transactions Report', icon: 'document-text',
      disabled: !this.authService.isEnabled(appFunctions.Paxes_Report) },
    { key: '2', value: 'Export to Excel', icon: 'download',
      disabled: !this.authService.isEnabled(appFunctions.Paxes_Export) },
  ];

  columnDefs: ColumnDef[] = [
    { field: 'brand', header: 'Brand' },
    { field: 'shipCode', header: 'Ship' },
    { field: 'cruiseDate', header: 'Cruise Date', type: 'Date' },
    { field: 'paxId', header: 'Pax ID' },
    { field: 'name', header: 'Name' },
    { field: 'surname', header: 'Surname' },
    { field: 'gender', header: 'Gender' },
    { field: 'score', header: 'Score', type: 'number' },
    { field: 'scoreStatus', header: 'Score Status', noFilter: true },
    { field: 'placeOfBirth', header: 'POB' },
    { field: 'dateOfBirth', header: 'DOB', type: 'Date' },
    { field: 'residenceCountry', header: 'Country of Residence' },
    { field: 'nationality', header: 'Nationality' },
    { field: 'isPep', header: 'Is PEP', type: 'boolean' },
    { field: 'taxId', header: 'Tax ID' },
    { field: 'embDate', header: 'Embark Date', type: 'Date' },
    { field: 'disDate', header: 'Disembark Date', noFilter: true },
    { field: 'bookingNumber', header: 'Booking No.' },
    { field: 'cruiseItineraryId', header: 'Itinerary ID' },
    { field: 'documentType', header: 'Doc. Type' },
    { field: 'documentNumber', header: 'Doc. Number' },
    { field: 'documentIssueDate', header: 'Doc. Issue Date', type: 'Date' },
    { field: 'documentIssuePlace', header: 'Doc. Issue Place' },
    { field: 'documentExpiryDate', header: 'Doc. Expiry Date', type: 'Date' },
    { field: 'dueDiligence', header: 'Due Diligence', type: 'boolean' },
    { field: 'riskyBehaviors', header: 'Risky Behaviors', type: 'boolean' },
    { field: 'riskyTransactions', header: 'Risky Transactions', type: 'boolean' },
    { field: 'riskyCountry', header: 'High Risk Country', type: 'boolean' },
    { field: 'operationStatus', header: 'Operations Status' },
    { field: 'bancaItaliaSentDate', header: 'Operation Date', type: 'Date' },
    { field: 'operationUserID', header: 'User ID' },
    { field: 'notes', header: 'Note' },
  ];
  sortColumns = ['surname', 'name'];


  ngOnInit(): void {
    forkJoin([this.dataService.getScoreStatus(),
              this.dataService.getOperationStatus()]).subscribe(([x,y]) => {

      this.scoreStatus = x;
      this.selectedScoreStatus = this.scoreStatus[0].key;
      this.operationStatus = y;
      this.fromDate = this.i18n.today(-28);
      this.toDate = this.i18n.today(28);

      this.setFilters()
      this.getData();
    });

    this.getColumnStatus(this.columnDefs);
  }

  private getData(alertMessage?: string) {
    this.messageService.waiting();

    this.dataService.getPaxDrillDown(1, this.sortColumns, this.allFilters).subscribe(x => {
      this.checkedIds = [];
      this.numChecked = 0;
      this.table.initData(x[0], x[1]);
      if (alertMessage) {
        this.messageService.alert(alertMessage);
      } else {
        this.messageService.endWaiting();
      }
    });
  }

  protected fetchData = (page: number): Observable<object[]> => {
    return this.dataService.getPaxDrillDown(page, this.sortColumns, this.allFilters).pipe(
      map(x => x[0])
    );
  }

  onSearchClick() {
    this.setFilters();
    this.getData();
  }

  onSendToVCClick() {
    this.messageService.confirm(
      'Send To Visual Compliance',
      `Are you sure to send the ${this.numChecked} selected pax`,
      [['No', null], ['Yes', () => {
        this.messageService.waiting();

        this.dataService.setPax(this.checkedIds, { sentToVisualCompliance: true }).subscribe(() => {
          this.getData('Data successfully sent to VC');
        });
      }]]
    );
  }

  onTableChecksChange() {
    this.numChecked = this.checkedIds.length;
  }

  onTableFilterChange() {
    this.setFilters();
    this.getData();
  }

  onTableSortChange() {
    if (this.sortColumns.length == 0) {
      this.sortColumns.push('surname');
      this.sortColumns.push('name');
    }
    this.getData();
  }

  onTableRowClick([row, cellIndex]: [object, number]) {
    const data = row as PaxDrillDown;
    this.paxPopup.id = data.id;
    this.paxPopup.name = data.name;
    this.paxPopup.surname = data.surname;
    this.paxPopup.visible = true;
  }

  onTableLayoutChange() {
    this.saveColumnStatus(this.columnDefs);
  }

  onPaxPopupDataChange() {
    this.dataService.getPaxDrillDown(1, [], [['id', 'Equal', this.paxPopup.id]]).subscribe(([data, numRows]) => {
      if (numRows == 1) {
        this.table.refreshRow(data[0]);
      }
    });
  }

  onMenuItemClick(itemKey: string) {
    this.messageService.waiting();
    if (itemKey == '1') {
      this.dataService.getPaxPDF(this.sortColumns, this.allFilters).subscribe(x => {
        this.messageService.endWaiting();
        this.dataService.saveAs(x.file, (x.filename ? x.filename : 'PaxDrilldown_Report.pdf'));
      });
    } else {
      this.dataService.getPaxExcel(this.sortColumns, this.allFilters).subscribe(x => {
        this.messageService.endWaiting();
        this.dataService.saveAs(x.file, (x.filename ? x.filename : 'PaxDrilldown_Export.zip'));
      });
    }
  }

  private setFilters() {
    this.allFilters = [
      ['disDate', 'GreaterEqual', this.fromDate],
      ['disDate', 'LessEqual', this.toDate],
      ...this.getTableFilters(this.tableFilters, this.columnDefs)
    ];
    if (this.selectedScoreStatus != this.scoreStatus[0].key) {   // All
      this.allFilters.push(['scoreStatusId', 'Equal', this.selectedScoreStatus]);
    }
  }

  tableRowCheckable(data: object): boolean {
    return !(data as PaxDrillDown).sentToVisualCompliance;
  }
}
