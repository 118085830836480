@if (visible) {
  <div class="z-50 fixed top-0 left-0 w-screen h-screen bg-slate-900 bg-opacity-50 flex justify-center">

    <div class="grid grid-rows-[1fr_auto_1.5fr]">
      <div></div>

        <!-- Dialog -->
        <div class="bg-white rounded-lg p-4 min-w-96 max-w-full min-h-44 max-h-full flex flex-col">

          <!-- Header -->
          <div class="pl-4 pb-4 flex justify-between w-full">
            <div class="text-gray-900 font-semibold text-lg max-w-96 overflow-hidden text-ellipsis">{{title}}</div>
            <button
              class="flex items-center justify-center -mt-2 w-10 h-10 p-2 rounded-full
                      hover:cursor-pointer hover:bg-gray-50
                      outline-offset-2 outline-yellow-200"
              (click)="onDismiss()">

              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </button>
          </div>

          <!-- Content -->
          <div class="px-4 overflow-x-hidden overflow-y-auto">
            <ng-content></ng-content>
          </div>

          <!-- Button Bar -->
          <div class="px-3 pt-3 mt-auto flex justify-end w-full">
            @for (button of buttons; track $index) {
              <app-button
                [shape]="$index == buttons.length-1 ? 'standard' : 'outline'"
                [disabled]="buttonsDisabled[$index]"
                (click)="onButtonClick($index)">
                {{button}}
              </app-button>
            }
          </div>
        </div>

      <div></div>
    </div>

  </div>
}
