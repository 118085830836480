@if (visible) {

  @if (message.type == 'MessageWaiting' && message.event) {
    <div class="fixed animate-progress-bar top-0 bg-blue-800 w-[40%] h-[2px]"></div>
  }


  @if (message.type == 'MessageAlert') {
    <div
      class="fixed top-12 left-1/2 -translate-x-1/2 pl-4 pr-8 py-4 rounded flex flex-row gap-4
            text-blue-900 bg-blue-50 border border-l-8 border-blue-800 shadow-xl
              transition-opacity duration-[2000ms]"
      [class.opacity-0]="isDelayOver">

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
      </svg>

      <div class="min-w-64">{{message.text}}</div>

    </div>
  }

  @if (message.type == 'MessageConfirm') {
    <app-dialog
      [title]="message.title"
      [buttons]="dialogButtons"
      (buttonClick)="onButtonClick($event)"
      [(visible)]="visible">

      <div class="max-w-96">
        @for (row of formattedText; track $index) {
          <div>
            @for (piece of row; track $index) {
              @if (piece.startsWith('\\b')) {
                <strong>{{piece.substring(2)}}</strong>
              } @else {
                {{piece}}
              }
            }
          </div>
        }
      </div>

    </app-dialog>
  }


  <!-- <div class="fixed top-0 left-0 w-screen h-screen bg-transparent"></div> -->
}
