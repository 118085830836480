import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-input-number',
  standalone: true,
  imports: [InputComponent],
  templateUrl: './input-number.component.html',
  styles: ``
})
export class InputNumberComponent implements OnChanges {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() value: number = 0;
  @Output() valueChange = new EventEmitter<number>();

  stringValue: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.stringValue = changes['value'].currentValue.toString();
    }
  }

  onChange(value: string) {
    this.valueChange.emit(+value);
  }
}
