<div class="flex flex-col w-full h-full">
  <div class="flex-shrink flex flex-row justify-between pt-2">
    <div class="flex flex-row flex-wrap items-end">
      <app-select
        class="w-52"
        label="Operation Status"
        [items]="operationStatus"
        [readonly]="isReadOnly"
        [selectedKey]="selectedOperationStatus"
        (selectedKeyChange)="onOperationStatusChange($event)">
      </app-select>

      <app-input-date
        class="w-44"
        label="Operation Date"
        [disabled]="!selectedOperationStatus || isReadOnly"
        [(value)]="bancaItaliaSentDate">
      </app-input-date>

      <app-button (click)="onButtonApply()" [disabled]="!selectedOperationStatus || numChecked == 0">Apply</app-button>
    </div>
  </div>

  <div class="flex-auto w-full overflow-hidden">
    <app-table #table
      [columnDefs]="columnDefs"
      [columnPicker]="true"
      [statusBar]="true"
      [fetchData]="fetchData"
      idField="id"
      [checkedIds]="isReadOnly ? undefined : checkedIds"
      [filters]="tableFilters"
      [sortColumns]="sortColumns"
      (checksChange)="onTableChecksChange()"
      (layoutChange)="onTableLayoutChange()"
      (filterChange)="onTableFilterChange()"
      (sortChange)="onTableSortChange()"
      (cellClick)="onTableRowClick($event)">
    </app-table>
  </div>
</div>

<app-pax-popup
  [id]="paxPopup.id"
  [name]="paxPopup.name"
  [surname]="paxPopup.surname"
  [(visible)]="paxPopup.visible"
  (dataChange)="onPaxPopupDataChange()">
</app-pax-popup>
