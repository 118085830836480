@switch (name) {

@case ("Costa-logo") {
  <svg width="233" height="164" viewBox="0 0 233 164" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-12 w-12">
    <g>
      <path d="M142.09 66.2785C134.389 79.8804 122.987 85.2155 107.121 85.2155C93.9518 85.2155 82.1967 80.3148 74.48 72.6439C82.5569 90.1271 100.16 102.251 120.584 102.251C137.805 102.251 153.022 93.6329 162.212 80.4501L142.09 66.2785Z" fill="#E7AF2A"/>
      <path d="M69.7754 51.1136C69.7754 67.3066 86.4945 80.4326 107.121 80.4326C112.848 80.4556 118.527 79.4018 123.864 77.3263C122.778 77.4629 121.685 77.5315 120.59 77.5315C106.091 77.5315 94.3357 65.7065 94.3357 51.1179C94.3357 36.5293 106.091 24.7044 120.59 24.7044C124.818 24.7058 128.982 25.7314 132.727 27.6935C136.472 29.6555 139.686 32.4955 142.094 35.9705L162.216 21.7945C153.026 8.61388 137.809 -0.00218201 120.59 -0.00218201C92.5282 -0.00218201 69.7776 22.8816 69.7754 51.1114V51.1136Z" fill="#3270A0"/>
      <path d="M49.8475 141.505C48.0369 144.117 45.6205 146.252 42.8051 147.726C39.9896 149.201 36.8589 149.972 33.6806 149.972C22.779 149.972 13.9424 141.083 13.9424 130.108C13.9424 119.132 22.779 110.243 33.6806 110.243C36.8591 110.244 39.99 111.015 42.8055 112.49C45.621 113.965 48.0373 116.1 49.8475 118.713L61.2664 110.671C58.1775 106.213 54.0547 102.569 49.2506 100.052C44.4465 97.5353 39.1041 96.2195 33.6806 96.2175C15.0798 96.2175 0 111.389 0 130.101C0 148.813 15.0798 163.983 33.6806 163.983C39.104 163.981 44.4463 162.666 49.2504 160.149C54.0545 157.632 58.1774 153.989 61.2664 149.531L49.8475 141.505Z" fill="#3270A0"/>
      <path d="M110.295 129.584C107.823 123.69 103.157 118.987 97.2829 116.469C92.788 114.561 87.8244 114.045 83.0332 114.986C78.2419 115.928 73.8429 118.285 70.4043 121.751C66.9619 125.192 64.6161 129.576 63.6632 134.349C62.7103 139.122 63.1929 144.071 65.0501 148.57C66.9074 153.069 70.056 156.916 74.0984 159.627C78.1408 162.338 82.8958 163.791 87.7631 163.801C90.9789 163.805 94.1634 163.17 97.132 161.933C100.101 160.697 102.794 158.883 105.056 156.598C108.497 153.115 110.83 148.692 111.762 143.887C112.694 139.081 112.184 134.107 110.295 129.59V129.584ZM79.1099 130.505C80.2412 129.361 81.5891 128.454 83.0749 127.838C84.5606 127.221 86.1544 126.906 87.7631 126.912C94.4582 126.912 99.9046 132.408 99.9046 139.165C99.9106 140.774 99.5992 142.368 98.9883 143.857C98.3774 145.345 97.4789 146.699 96.3442 147.84C95.2134 148.975 93.8696 149.875 92.3899 150.49C90.9102 151.104 89.3238 151.421 87.7216 151.421C86.1194 151.421 84.533 151.104 83.0533 150.49C81.5736 149.875 80.2298 148.975 79.099 147.84C76.8166 145.532 75.5365 142.418 75.5365 139.172C75.5365 135.927 76.8166 132.812 79.099 130.505H79.1099Z" fill="#3270A0"/>
      <path d="M181.079 152.304C179.645 152.612 178.521 152.832 177.096 152.871C175.29 152.924 173.998 152.54 173.147 151.699C172.238 150.806 171.808 149.39 171.837 147.37V124.013H180.931V113.415H171.837V100.806L160.485 104.646L160.331 113.456H153.402V124.019H159.466V147.377C159.466 152.208 160.483 156.065 162.492 158.846C164.5 161.627 167.495 163.31 171.363 163.808C172.009 163.886 172.66 163.923 173.31 163.919C176.888 163.919 181.018 163.005 181.223 162.959L181.494 162.898V152.225L181.079 152.304Z" fill="#3270A0"/>
      <path d="M146.988 140.077C144.967 138.075 141.924 136.242 136.809 133.945L135.358 133.29C133.515 132.472 132.059 131.823 130.887 131.276C129.169 130.472 128.361 129.372 128.669 128.254C129.071 126.796 131.352 125.417 134.345 125.711C136.449 125.93 137.912 126.44 140.051 128.669C140.132 128.77 140.457 129.186 140.852 129.719L141.07 130.014L149.027 123.293L148.922 123.055C146.724 118.11 140.101 114.519 133.175 114.519C128.89 114.519 124.661 115.903 121.553 118.313C119.977 119.514 118.679 121.04 117.746 122.788C116.787 124.619 116.292 126.657 116.305 128.724C116.287 130.616 116.717 132.486 117.558 134.181C118.344 135.706 119.41 137.07 120.701 138.2C122.072 139.433 124.129 140.981 128.97 143.098C129.979 143.535 130.913 143.923 131.808 144.295C134.864 145.552 137.06 146.458 137.789 147.787C137.969 148.17 138.055 148.59 138.038 149.012C138.012 149.351 137.917 149.68 137.759 149.981C137.6 150.281 137.382 150.546 137.117 150.758C136.069 151.688 133.624 152.588 131.166 152.426C127.193 152.164 123.186 149.78 121.415 146.626L121.396 146.593L121.339 146.519L121.284 146.443L121.029 146.072L114.076 154.199L114.177 154.404C115.425 156.932 117.748 159.259 120.725 160.953C124.086 162.837 127.879 163.815 131.732 163.79C136.635 163.79 141.236 162.302 144.685 159.599C146.391 158.289 147.794 156.625 148.796 154.722C149.815 152.749 150.339 150.558 150.324 148.337C150.328 144.969 149.265 142.341 146.988 140.077Z" fill="#3270A0"/>
      <path d="M221.01 163.557H231.981V113.613L221.25 117.023L220.957 119.165C219.172 117.711 214.93 114.535 207.168 114.53C203.893 114.53 199.285 115.89 196.714 117.206C193.249 118.992 190.255 121.571 187.977 124.734C185.698 127.896 184.199 131.552 183.602 135.404C183.004 139.255 183.326 143.194 184.54 146.898C185.753 150.602 187.825 153.966 190.586 156.718C192.834 158.97 195.506 160.755 198.447 161.971C201.388 163.187 204.54 163.809 207.722 163.801H207.847C210.503 163.788 216.548 162.74 220.944 158.918L221.01 163.557ZM219.977 139.165C219.977 139.383 219.977 139.588 219.962 139.798C219.964 139.825 219.964 139.853 219.962 139.881C219.962 139.907 219.962 139.935 219.962 139.968C219.962 140.125 219.944 140.274 219.929 140.422C219.633 143.422 218.239 146.208 216.015 148.244C213.699 150.351 210.66 151.485 207.529 151.41C204.399 151.334 201.418 150.055 199.207 147.838C197.494 146.124 196.327 143.941 195.854 141.565C195.381 139.189 195.623 136.725 196.549 134.486C197.475 132.247 199.043 130.333 201.056 128.985C203.069 127.637 205.437 126.915 207.86 126.912C214.533 126.912 219.977 132.408 219.977 139.165V139.165Z" fill="#3270A0"/>
    </g>
  </svg>
}
@case ("beaker") {
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 0 1-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 0 1 4.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0 1 12 15a9.065 9.065 0 0 0-6.23-.693L5 14.5m14.8.8 1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0 1 12 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
  </svg>
}
@case ("chart-bar-square") {
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
  </svg>
}
@case ("download") {
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
  </svg>
}
@case ("document") {
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
  </svg>
}
@case ("document-text") {
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
  </svg>
}
@case ("rocket-launch") {
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
  </svg>

}
@case ("settings") {
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  </svg>
}
@case ("table-cells") {
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
  </svg>
}
@case ("user") {
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
  </svg>
}

}
