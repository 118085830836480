import { Routes } from '@angular/router';
import { OverallComponent } from './main/pages/overall/overall.component';
import { PaxDrilldownComponent } from './main/pages/pax-drilldown/pax-drilldown.component';
import { DueDiligenceRegisterComponent } from './main/pages/due-diligence-register/due-diligence-register.component';
import { SettingsDueDiligenceComponent } from './main/pages/settings/due-diligence/due-diligence.component';
import { SettingsCountriesComponent } from './main/pages/settings/countries/countries.component';
import { SettingsRiskScoresComponent } from './main/pages/settings/risk-scores/risk-scores.component';
import { SuspiciousTrxComponent } from './main/pages/suspicious-trx/suspicious-trx.component';
import { PepApprovalComponent } from './main/pages/pep-approval/pep-approval.component';

export const routes: Routes = [
  { path: 'overall', component: OverallComponent },
  { path: 'pep-approval', component: PepApprovalComponent },
  { path: 'suspicious-trx', component: SuspiciousTrxComponent },
  { path: 'due-diligence-register', component: DueDiligenceRegisterComponent },
  { path: 'pax-drilldown', component: PaxDrilldownComponent },
  { path: 'settings/due-diligence', component: SettingsDueDiligenceComponent },
  { path: 'settings/countries', component: SettingsCountriesComponent },
  { path: 'settings/risk-scores', component: SettingsRiskScoresComponent },
];
