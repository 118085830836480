export const environment = {
  production: false,

  msalConfig: {
    auth: {
      clientId: '941d71ec-f0f1-40d0-918b-52007ec0845c',
      authority: 'https://login.microsoftonline.com/9e37b9e9-05de-4906-b089-536f19689074'
    }
  },

  msalGuardConfig: {
    authRequest: {
      scopes: [],
      prompt: 'select_account'
    }
  },

  apiConfig: {
    url: 'https://app-amlcollector-api-test.costa.it',
    scopes: ['api://941d71ec-f0f1-40d0-918b-52007ec0845c/default']
  }
};
