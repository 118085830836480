<app-dialog
  [buttons]="['Cancel', 'Ok']"
  [title]="name + ' ' + surname"
  (buttonClick)="onButtonClick($event)"
  [visible]="visible"
  (visibleChange)="onVisibleChange($event)">

  <div class="w-[60rem] h-[26rem] relative">

    <!-- HEADER -->
    <div class="grid grid-cols-[13rem_10rem_13rem_auto] gap-x-1">

      <app-select
        label="Operation Status"
        [items]="operationStatus"
        [cancellable]="true"
        [readonly]="!isOperationStatusEnabled"
        [selectedKey]="dataChanged.operationStatusId"
        (selectedKeyChange)="onOperationStatusChange($event)">
      </app-select>

      <app-input-date
        label="Operation Date"
        [disabled]="!dataChanged.operationStatusId"
        [(value)]="dataChanged.bancaItaliaSentDate">
      </app-input-date>

      <app-textarea
        class="h-full row-span-2"
        label="Note"
        [(value)]="dataChanged.notes"
        [readonly]="!isNoteEnabled">
      </app-textarea>

      <div class="flex flex-col pl-1 py-1 row-span-2">
        <label class="ml-3 font-medium text-gray-600">Score</label>
        <div class="flex flex-row items-center h-28 border border-gray-300 rounded-lg">

          <!-- score line -->
          <div class="w-36 pl-2 stroke-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 0 140 42">
              <rect x="0" y="24" width="50" height="2" fill="limegreen"></rect>
              <rect x="50" y="24" width="50" height="2" fill="red"></rect>
              <text x="0" y="38" text-anchor="middle" fill="black" font-size="10px">0</text>
              <text x="50" y="38" text-anchor="middle" fill="black" font-size="10px">{{totalScore.threshold}}</text>

              <text attr.x="{{totalScore.position}}" y="16" text-anchor="middle"
                [attr.fill]="totalScore.position < 50 ? 'limegreen' : 'red'"
                font-size="22px">{{totalScore.value}}</text>
              <path attr.d="M {{totalScore.position-5}} 20 l 10 0 l -5 4 Z" fill="lightgray"></path>
            </svg>
          </div>

          <!-- pie -->
          <div class="w-24 text-gray-100" [ngClass]="{ 'animate-pulse': loading || loadingScores }">
            @if (totalScore.value == 0 || loading || loadingScores) {
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-100 -100 200 200">
                <circle r="100" fill="currentColor"/>
              </svg>
            } @else {
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-500 -500 1000 1000" transform="rotate(-90)">
                @for (score of scores; track $index) {
                  @if (score.value > 0) {
                    <circle r="250"
                      attr.stroke="{{score.color}}" stroke-width="500" fill="none"
                      attr.pathLength="{{totalScore.value}}"
                      attr.stroke-dasharray="0 {{score.totalBefore}} {{score.value}} {{score.totalAfter}}">
                      <title>
                        {{score.name}}: {{score.value}}
                      </title>
                    </circle>
                  }
                }
                @for (score of scores; track $index) {
                  @if (score.value > 0 && score.value != totalScore.value) {
                    <line x1="0" y1="0" x2="500" y2="0" stroke="white" stroke-width="20"
                      attr.transform="rotate({{score.totalBefore * 360 / totalScore.value}})">
                    </line>
                  }
                }
              </svg>
            }
          </div>

          <div class="grid grid-cols-[.8rem_auto] items-center pl-3">
              @for (score of scores; track $index) {
                @if (score.value > 0 && !(loading || loadingScores)) {
                  <div class="w-2 h-2" [ngStyle]="{'background-color': score.color}">&nbsp;</div>
                  <div class="text-xs">{{score.name}}</div>
                }
              }
          </div>
        </div>
      </div>

      <div class="ml-4 flex flex-col justify-center">
        @if (!loading) {
          {{pax.sentToVisualCompliance ? 'Already sent to Visual Compliance' : 'Not sent to Visual Compliance'}}
        }
      </div>

      <app-select
        label="VC Feedback"
        [items]="vcFeedback"
        [readonly]="!pax.sentToVisualCompliance || !isVcFeedbackEnabled"
        [(selectedKey)]="dataChanged.vcStatusId">
      </app-select>
    </div>


    <!-- TABS -->
    <div class="flex my-4 gap-4 border-b border-b-gray-300">
      @for (tab of tabs; track $index) {
        <div
          class="px-4 border-b-2"
          [ngClass]="{ 'border-blue-900 font-medium': tabIndex == $index,
                       'border-transparent cursor-pointer': tabIndex != $index }"
          (click)="tabIndex = $index">

          {{tab}}
        </div>

      }
      @if (pax.isPep) {
        <div class="px-2 ml-auto -mt-2.5 mb-2.5  text-white rounded"
             [ngClass]="{
               'bg-red-400': !pax.isPEPApprovalPending && !pax.isPEPApproved,
               'bg-gradient-to-r via-yellow-400 to-yellow-400': pax.isPEPApprovalPending, 
               'from-red-400': pax.isPEPApprovalPending && !pax.isPEPApproved, 
               'from-green-400': pax.isPEPApprovalPending && pax.isPEPApproved, 
               'bg-green-400': !pax.isPEPApprovalPending && pax.isPEPApproved, 
        }">
          @if (pax.isPEPApprovalPending) {
            The pax is a PEP and the {{pax.isPEPApproved ? 'approval' : 'block'}} request is pending
          } @else {
            The pax is a PEP and is {{pax.isPEPApproved ? '' : 'NOT '}}approved
          }
        </div>
      }
    </div>

    @if (tabIndex == 0) {
      <div class="grid grid-cols-3 gap-2">

        <div class="flex flex-col">
          <div class="font-medium text-gray-700">Cruise Data</div>
          <div class="grid grid-cols-[8rem_auto]">
            <div>Pax ID</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-24"></div>}
              <div>{{pax.paxId}}</div>
            </div>
            <div>Booking No.</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-16"></div>}
              <div>{{pax.bookingNumber}}</div>
            </div>
            <div>Embark Date</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-20"></div>}
              <div>{{pax.embDate | date : 'dd/MM/yyyy'}}</div>
            </div>
            <div>Disembark Date</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-20"></div>}
              <div>{{pax.disDate | date : 'dd/MM/yyyy'}}</div>
            </div>
            <div>Brand</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-24"></div>}
              <div>{{pax.brand}}</div>
            </div>
            <div>Ship</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-16"></div>}
              <div>{{pax.shipCode}}</div>
            </div>
            <div>Cruise Date</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-20"></div>}
              <div>{{pax.cruiseDate | date : 'dd/MM/yyyy'}}</div>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="font-medium text-gray-700">Personal Data</div>
          <div class="grid grid-cols-[9rem_auto]">
            <div>Tax ID</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-32"></div>}
              <div>{{pax.taxId}}</div>
            </div>
            <div>Gender</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-12"></div>}
              @else {<div>{{pax.gender == 'F' ? 'Female' : 'Male'}}</div>}
            </div>
            <div>Nationality</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-24"></div>}
              <div>{{pax.nationality}}</div>
            </div>
            <div>Country of Resid.</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-20"></div>}
              <div>{{pax.residenceCountry}}</div>
            </div>
            <div>High Risk Country</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-8"></div>}
              <div>{{pax.riskyCountry | yesNo}}</div>
            </div>
            <div>POB</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-24"></div>}
              <div>{{pax.placeOfBirth}}</div>
            </div>
            <div>DOB</div>
            <div>
              @if (loading) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-20"></div>}
              <div>{{pax.dateOfBirth | date : 'dd/MM/yyyy'}}</div>
            </div>
          </div>
        </div>


        <div class="flex flex-col">
          @if (paxDocument.documentImage) {
            <div
              class="w-28 h-16 cursor-pointer shadow bg-cover" [style]="{'background-image': 'url(data:image/jpeg;base64,' + paxDocument.documentImage + ')'}"
              (click)="largeImage = true"></div>
          } @else {
            <div class="grid grid-cols-[1fr_4fr] gap-1 px-2 py-3 w-28 h-16 bg-gray-100 rounded-lg shadow"
                 [ngClass]="{ 'animate-pulse': loading || loadingDoc}">
              <div class="w-6 h-8 row-span-3 bg-gray-300"></div>
              <div class="w-18 h-1.5 bg-gray-300"></div>
              <div class="w-10 h-1.5 bg-gray-300"></div>
              <div class="w-14 h-1.5 bg-gray-300"></div>
              <div class="w-full h-1.5 col-span-2 bg-gray-300"></div>
            </div>
          }

          <div class="mt-2 grid grid-cols-[8rem_auto]">
            <div>Document Type</div>
            <div>
              @if (loading || loadingDoc) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-24"></div>}
              @else {<div>{{paxDocument.documentType}}</div>}
            </div>
            <div>Document No.</div>
            <div>
              @if (loading || loadingDoc) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-12"></div>}
              @else {<div>{{paxDocument.documentNumber}}</div>}
            </div>
            <div>Issue Date</div>
            <div>
              @if (loading || loadingDoc) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-20"></div>}
              @else {<div>{{paxDocument.documentIssueDate | date : 'dd/MM/yyyy'}}</div>}
            </div>
            <div>Issue Place</div>
            <div>
              @if (loading || loadingDoc) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-28"></div>}
              @else {<div>{{paxDocument.documentIssuePlace}}</div>}
            </div>
            <div>Expiry Date</div>
            <div>
              @if (loading || loadingDoc) {<div class="bg-gray-100 animate-pulse border-y-2 border-white h-full w-20"></div>}
              @else {<div>{{paxDocument.documentExpiryDate | date : 'dd/MM/yyyy'}}</div>}
            </div>
          </div>
        </div>

      </div>

    } @else if (tabIndex == 1) {
      <div class="mt-5 h-48">

        <app-table
          [columnDefs]="behaviors.columnDefs"
          [data]="behaviors.data ">
        </app-table>

      </div>
    } @else {

      <div class="flex flex-col mt-5">
        <div class="h-[11.3rem]">
          <app-table
            [columnDefs]="transactions.columnDefs"
            [data]="transactions.data">
          </app-table>
        </div>

        <div class="flex justify-center">
          <div class="ml-3">Due Diligence Transactions: {{pax.dueDiligence | yesNo}}</div>
          <div class="ml-16">Risky Transactions: {{pax.riskyTransactions | yesNo}}</div>
        </div>
      </div>

    }

    @if (largeImage) {
      <div class="absolute top-0 left-0 w-full h-full bg-white bg-opacity-90 flex justify-center items-center">
        <div class="bg-white p-2 border border-gray-300 shadow-xl relative">
          <div class="max-w-[52rem] max-h-[22rem] overflow-auto">
            <img [src]="'data:image/jpeg;base64,' + paxDocument.documentImage">
          </div>
          <div class="absolute -top-3 -right-3 cursor-pointer" (click)="largeImage = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
              <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
      </div>
    }
  </div>


</app-dialog>
