import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { StorageService } from './storage.service';
import { DashboardData, KeyValue, ValueListData, FilterDef, PaxDrillDown, PaxTransaction, PaxDocument, Setting, Country, PaxScores, PaxBehavior, Pax } from './data.model';
import { Observable } from 'rxjs/internal/Observable';
import { map, of } from 'rxjs';
import { I18nService } from '../i18n.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private paxScoreStatus: KeyValue[] = [];
  private paxOperationStatus: KeyValue[] = [];
  private vcFeedback: KeyValue[] = [];

  constructor(
    private backendService: BackendService,
    private storageService: StorageService,
    private i18n: I18nService
  ) {
    this.getScoreStatus().subscribe();
    this.getOperationStatus().subscribe();
    this.getVCFeedback().subscribe();
  }

  public setItem(key: string, value: any): void {
    this.storageService.setItem(key, value);
  }

  public getItem<T>(key: string): T | null {
    return this.storageService.getItem(key);
  }

  public getScoreStatus(): Observable<KeyValue[]> {
    return this.getValueList('Pax_Score_Status', this.paxScoreStatus);
  }

  public getOperationStatus(): Observable<KeyValue[]> {
    return this.getValueList('Pax_Change_Status', this.paxOperationStatus);
  }

  public getVCFeedback(): Observable<KeyValue[]> {
    return this.getValueList('VisualCompliance_Feedback', this.vcFeedback);
  }

  private getValueList(type: string, valueList: KeyValue[]): Observable<KeyValue[]> {
    return valueList.length > 0 ? of(valueList)
                      : this.backendService.httpGet<ValueListData[]>(`ValueList/${type}`)
                                           .pipe(map(x => {
                                                       valueList.splice(0, valueList.length);
                                                       valueList.push(...x.sort((a, b) => a.order - b.order)
                                                                          .map(y => ({ key: y.id, value: y.value })));
                                                       return valueList;
                                                    }));
  }

  public getDashboard(): Observable<DashboardData> {
    return this.backendService.httpGet<DashboardData>('Dashboard');
  }

  public getPaxDrillDown(pageNumber: number, sortColumns: string[], filters: FilterDef[]): Observable<[PaxDrillDown[], number]> {
    return this.backendService.httpPost2<PaxDrillDown[]>('Paxes/Search', {
      pageNumber,
      pageSize: 50,
      sortings: this.getSortings(sortColumns),
      filterings: this.getFilterings(filters)
    });
  }

  public getPax(id: string): Observable<Pax> {
    return this.backendService.httpGet<Pax>(`Paxes/${id}`);
  }

  public setPax(ids: string[], values: {
    bancaItaliaSentDate?: Date,
    notes?: string,
    sentToVisualCompliance?: boolean,
    vcStatusId?: string}): Observable<void> {

    let payload: {
      ids: string[],
      bancaItaliaSentDate?: string,
      notes?: string,
      sentToVisualCompliance?: boolean,
      vcStatusId?: string
    } = { ids };

    if (values.bancaItaliaSentDate != undefined) { payload.bancaItaliaSentDate = this.i18n.toISOString(values.bancaItaliaSentDate) };
    if (values.notes != undefined) { payload.notes = values.notes };
    if (values.vcStatusId != undefined) { payload.vcStatusId = values.vcStatusId };
    if (values.sentToVisualCompliance != undefined) { payload.sentToVisualCompliance = values.sentToVisualCompliance };

    return this.backendService.httpPost<void>('Paxes/MassiveUpdate', payload);
  }

  public getPaxDocument(id: string): Observable<PaxDocument | null> {
    return this.backendService.httpGet<PaxDocument>(`Paxes/${id}/Document`);
  }

  public getPaxScores(id: string): Observable<PaxScores> {
    return this.backendService.httpGet<PaxScores>(`Paxes/${id}/GetScores`);
  }

  public getPaxTransactions(paxId: string): Observable<PaxTransaction[]> {
    return this.backendService.httpPost<PaxTransaction[]>(`Paxes/${paxId}/Transactions`, {
      pageNumber: 1,
      pageSize: 999,
      sortings: this.getSortings(['operationDate'])
    });
  }

  public getPaxRiskyBehaviors(paxId: string): Observable<PaxBehavior[]> {
    return this.backendService.httpPost<PaxBehavior[]>(`Paxes/${paxId}/RiskyBehaviors`, {
      pageNumber: 1, pageSize: 999
    });
  }

  public getPaxExcel(sortColumns: string[], filters: FilterDef[]): Observable<{file: Blob, filename: string}> {
    return this.backendService.httpPostBinary('Paxes/Export', {
      pageNumber: 1,
      pageSize: 99999,
      sortings: this.getSortings(sortColumns),
      filterings: this.getFilterings(filters)
    });
  }

  public getPaxPDF(sortColumns: string[], filters: FilterDef[]): Observable<{file: Blob, filename: string}> {
    return this.backendService.httpPostBinary('Paxes/Report', {
      pageNumber: 1,
      pageSize: 99999,
      sortings: this.getSortings(sortColumns),
      filterings: this.getFilterings(filters)
    });
  }

  public getPep(pageNumber: number, sortColumns: string[], filters: FilterDef[]): Observable<[PaxDrillDown[], number]> {
    return this.backendService.httpPost2<PaxDrillDown[]>('PEPApprovals/Search', {
      pageNumber,
      pageSize: 50,
      sortings: this.getSortings(sortColumns),
      filterings: this.getFilterings(filters)
    });
  }

  public getSuspiciousTrx(pageNumber: number, sortColumns: string[], filters: FilterDef[]): Observable<[PaxDrillDown[], number]> {
    return this.backendService.httpPost2<PaxDrillDown[]>('SuspiciousTransactions/Search', {
      pageNumber,
      pageSize: 50,
      sortings: this.getSortings(sortColumns),
      filterings: this.getFilterings(filters)
    });
  }

  public getPepExcel(sortColumns: string[], filters: FilterDef[]): Observable<{file: Blob, filename: string}> {
    return this.backendService.httpPostBinary('PEPApprovals/Export', {
      pageNumber: 1,
      pageSize: 99999,
      sortings: this.getSortings(sortColumns),
      filterings: this.getFilterings(filters)
    });
  }

  public setPep(ids: string[], isApproved: boolean): Observable<void> {
    return this.backendService.httpPost<void>('PEPApprovals/MassiveUpdate', { ids, isApproved });
  }

  public getDueDiligence(pageNumber: number, sortColumns: string[], filters: FilterDef[]): Observable<[PaxDrillDown[], number]> {
    return this.backendService.httpPost2<PaxDrillDown[]>('DueDiligence/Search', {
      pageNumber,
      pageSize: 50,
      sortings: this.getSortings(sortColumns),
      filterings: this.getFilterings(filters)
    });
  }

  public getDueDiligencePDF(sortColumns: string[], filters: FilterDef[]): Observable<{file: Blob, filename: string}> {
    return this.backendService.httpPostBinary('DueDiligence/Report', {
      pageNumber: 1,
      pageSize: 99999,
      sortings: this.getSortings(sortColumns),
      filterings: this.getFilterings(filters)
    });
  }

  /////////////////////////////////////////////////////////////////////////////////

  private getSortings(sortColumns: string[]) {
    return sortColumns.map((v,i) => ({
      condition: true,
      propertyName: v.startsWith('-') ? v.substring(1) : v,
      descending: v.startsWith('-'),
      priority: i
    }));
  }

  private getFilterings(filters: FilterDef[]) {
    return filters.length == 0 ? null : {
      logicalOperator: 'And',
      filters: filters.map(([propertyName, operator, value]) => ({
        condition: true,
        propertyName,
        operator,
        value: (value instanceof Date) ? this.i18n.toISOString(value) : value }))
    };
  }

  public getSettings(type: string): Observable<Setting[]> {
    return this.backendService.httpPost<Setting[]>(`Settings/${type}`, {
      pageNumber: 1, pageSize: 999
    });
  }

  public setSetting(id: string, value: number): Observable<Setting> {
    return this.backendService.httpPut<Setting>(`Settings`, {
      id, value
    });
  }

  public getCountries(): Observable<Country[]> {
    return this.backendService.httpPost<Country[]>('Countries/Search', {
      pageNumber: 1, pageSize: 999,
      sortings: this.getSortings(['description']),
    });
  }

  public getCountriesExcel(): Observable<{file: Blob, filename: string}> {
    return this.backendService.httpGetBinary('Countries/Export');
  }

  public setCountry(id: string, isRisky: boolean): Observable<Country> {
    return this.backendService.httpPut<Country>(`Countries`, {
      id, isRisky
    });
  }

  public saveAs(file: Blob, filename: string) {
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }

}
