<div class="flex flex-col w-full h-full">
  <div class="flex-shrink flex flex-row justify-between pt-2">
    <div class="flex flex-row flex-wrap items-end">
      <app-input-date
        class="w-44"
        label="Disemb. Date From"
        [toDate]="mainFilters.toDate"
        [(value)]="mainFilters.fromDate">
      </app-input-date>
      <app-input-date
        class="w-44"
        [fromDate]="mainFilters.fromDate"
        [(value)]="mainFilters.toDate"
        label="Disemb. Date To">
      </app-input-date>
      <app-input [(value)]="mainFilters.name" label="Name" class="w-36"></app-input>
      <app-input [(value)]="mainFilters.surname" label="Surname" class="w-36"></app-input>
      <app-input [(value)]="mainFilters.taxId" label="Tax ID" class="w-36"></app-input>
      <app-input [(value)]="mainFilters.brand" label="Brand" class="w-36"></app-input>
      <app-button (click)="onButtonSearch()">Search</app-button>
    </div>

    <div class="flex flex-row items-end">
      <app-menu [items]="menuItems" (itemClick)="onMenuChoice($event)"></app-menu>
    </div>
  </div>

  <div class="flex-auto w-full overflow-hidden">
    <app-table #table
      [columnDefs]="columnDefs"
      [columnPicker]="true"
      [statusBar]="true"
      [fetchData]="fetchData"
      [filters]="tableFilters"
      [sortColumns]="sortColumns"
      (filterChange)="onTableFilterChange()"
      (layoutChange)="onTableLayoutChange()"
      (sortChange)="onTableSortChange()"
      (cellClick)="onTableRowClick($event)">
    </app-table>
  </div>
</div>

<app-pax-popup
  [id]="paxPopup.id"
  [name]="paxPopup.name"
  [surname]="paxPopup.surname"
  [(visible)]="paxPopup.visible"
  (dataChange)="onPaxPopupDataChange()">
</app-pax-popup>
