<div class="flex flex-col w-full h-full">
  <div class="flex-shrink flex flex-row justify-between pt-2">
    <div class="flex flex-row flex-wrap items-end">
      <app-input-date
        [toDate]="toDate"
        [(value)]="fromDate"
        label="Disemb. Date From" class="w-44"></app-input-date>
      <app-input-date
        [fromDate]="fromDate"
        [(value)]="toDate"
        label="Disemb. Date To" class="w-44">
      </app-input-date>
      <app-button (click)="onButtonSearch()">Search</app-button>
    </div>

    <div class="flex flex-row items-end">

      <div class="grid grid-cols-2 gap-x-8 gap-y-0">
        <div class="relative flex items-center">
          <div class="flex-grow border-t border-gray-400"></div>
          <span class="flex-shrink mx-2">VC Feedback</span>
          <div class="flex-grow border-t border-gray-400"></div>
        </div>
        <div>
          <div class="relative flex items-center">
            <div class="flex-grow border-t border-gray-400"></div>
            <span class="flex-shrink mx-2">PEP Status</span>
            <div class="flex-grow border-t border-gray-400"></div>
        </div>
        </div>

        <div class="flex">
          <app-button (click)="onButtonVCFeedback(0)" [disabled]="!checksSentToVC || !isVCFeedbackEnabled">
            {{vcFeedback[0].value}}
          </app-button>
          <app-button (click)="onButtonVCFeedback(1)" [disabled]="!checksSentToVC || !isVCFeedbackEnabled">
            {{vcFeedback[1].value}}
          </app-button>
        </div>
        <div class="flex">
          <app-button (click)="onButtonApprove(true)" [disabled]="checksApproveType != 'Approve' || !isApproveEnabled">
            Approve
          </app-button>
          <app-button (click)="onButtonApprove(false)" [disabled]="checksApproveType != 'Block' || !isBlockEnabled">
            Block
          </app-button>
        </div>
      </div>


      <app-menu [items]="menuItems" (itemClick)="onMenuChoice($event)"></app-menu>
    </div>
  </div>

  <div class="flex-auto w-full overflow-hidden">
    <app-table #table
      [columnDefs]="columnDefs"
      [columnPicker]="true"
      [statusBar]="true"
      [fetchData]="fetchData"
      idField="id"
      [checkedIds]="isReadOnly ? undefined : checkedIds"
      [checkable]="tableRowCheckable"
      [filters]="tableFilters"
      [sortColumns]="sortColumns"
      (checksChange)="onTableChecksChange($event)"
      (layoutChange)="onTableLayoutChange()"
      (filterChange)="onTableFilterChange()"
      (sortChange)="onTableSortChange()"
      (cellClick)="onTableRowClick($event)">
    </app-table>
  </div>
</div>

<app-pax-popup
  [id]="paxPopup.id"
  [name]="paxPopup.name"
  [surname]="paxPopup.surname"
  [(visible)]="paxPopup.visible"
  (dataChange)="onPaxPopupDataChange()">
</app-pax-popup>
