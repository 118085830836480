<div class="relative m-1">
    <button #button
      class="flex items-center justify-center w-10 h-10 mt-[-1px] p-1 rounded-full text-gray-900
             hover:cursor-pointer hover:bg-gray-200
             outline-offset-2 outline-yellow-200"
      (click)="onButtonClick()"
      (focus)="onButtonFocus()"
      (blur)="onButtonBlur()">

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
      </svg>
    </button>

    <app-dropdown [anchor]="button"  [(visible)]="showMenu">

      <div class="flex flex-col">
        @for (item of items; track $index) {
          @if (item == null) {
            <div class="divide-y divide-gray-300">
              <div class="h-2"></div>
              <div class="h-2"></div>
            </div>
          } @else {

            @if (item.disabled) {

              <div
                class="flex mx-2 px-4 py-1 whitespace-nowrap text-gray-400">

                @if (itemsWithIcon) {
                  <div class="me-3 w-5 self-center">
                    @if (item.icon) {
                      <app-icon [name]="item.icon"></app-icon>
                    }
                  </div>
                }

                <div>{{item.value}}</div>
              </div>

            } @else {

              <div
                class="flex mx-2 px-4 py-1 cursor-pointer rounded whitespace-nowrap hover:text-gray-900 hover:bg-gray-100"
                (click)="onItemClick(item.key)">

                @if (itemsWithIcon) {
                  <div class="me-3 w-5 self-center">
                    @if (item.icon) {
                      <app-icon [name]="item.icon"></app-icon>
                    }
                  </div>
                }

                <div>{{item.value}}</div>
              </div>

            }

        }
        }
      </div>

    </app-dropdown>

</div>
