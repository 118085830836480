import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '../../base-page';
import { ColumnDef, TableComponent } from '../../../../shared/components/table/table.component';
import { MainModule } from '../../../main.module';
import { Country } from '../../../../services/data/data.model';
import { FormsModule } from '@angular/forms';
import { appFunctions } from '../../../../app.model';

@Component({
  selector: 'app-settings-countries',
  standalone: true,
  imports: [MainModule,FormsModule],
  templateUrl: './countries.component.html',
  styles: ``
})
export class SettingsCountriesComponent extends BasePage implements OnInit{
  constructor(private injector: Injector) { super(injector, 'settings.countries'); }

  @ViewChild('table') table!: TableComponent;
  isReadOnly = !this.authService.isEnabled(appFunctions.Countries_Update);

  menuItems = [
    { key: '1', value: 'Export to Excel', icon: 'download',
      disabled: !this.authService.isEnabled(appFunctions.Countries_Export) },
  ];

  columnDefs: ColumnDef[] = [
    { field: 'code', header: 'Code' },
    { field: 'description', header: 'Name' },
    { field: 'isRisky', header: 'High Risk' },
    { field: 'taxCode', header: 'Tax Code' },
    { field: 'lastUpdateDate', header: 'Last Update Date' },
    { field: 'updatedBy', header: 'Update By' },
  ]

  data: Country[] = [];
  edit = {
    id: '',
    country: '',
    isHighRisk: '',
    visible: false
  };

  ngOnInit(): void {
    this.messageService.waiting()

    this.dataService.getCountries().subscribe(x => {
      this.data = x;
      this.messageService.endWaiting();
    });
  }

  onTableRowClick([row, cellIndex]: [object, number]) {
    const data = row as Country;
    this.edit.id = data.id
    this.edit.country = data.description;
    this.edit.isHighRisk = data.isRisky ? 'Yes' : 'No';
    this.edit.visible = true;
  }

  onEditClick(button: number) {
    if (button == 1) {
      this.messageService.waiting()
      this.dataService.setCountry(this.edit.id, this.edit.isHighRisk == 'Yes').subscribe(x => {
        const i = this.data.findIndex(x => x.id == this.edit.id);
        if (i != -1) {
          this.data[i] = x;
          this.table.refreshRow(x);
        }

        this.messageService.endWaiting();
      });
    }
  }

  onMenuItemClick(itemKey: string) {
    this.messageService.waiting();
    this.dataService.getCountriesExcel().subscribe(x => {
      this.messageService.endWaiting();
      this.dataService.saveAs(x.file, (x.filename ? x.filename : 'Countries_Export.xlsx'));
    });
  }
}
