import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '../base-page';
import { ColumnDef, TableComponent } from '../../../shared/components/table/table.component';
import { FilterDef, KeyValue, PaxDrillDown } from '../../../services/data/data.model';
import { CommonModule } from '@angular/common';
import { MainModule } from '../../main.module';
import { PaxPopupComponent } from '../pax-popup/pax-popup.component';
import { Observable, map } from 'rxjs';
import { appFunctions } from '../../../app.model';

@Component({
  selector: 'app-suspicious-trx',
  standalone: true,
  imports: [CommonModule, MainModule, PaxPopupComponent],
  templateUrl: './suspicious-trx.component.html',
  styles: ``
})
export class SuspiciousTrxComponent extends BasePage implements OnInit {
  constructor(private injector: Injector) { super(injector, 'suspicious-trx'); }

  @ViewChild('table') table!: TableComponent;
  isReadOnly = !this.authService.isEnabled(appFunctions.SuspiciousTransactions_MassiveUpdate_UIF);

  paxPopup = {id: '', name: '', surname: '', visible: false};

  operationStatus: KeyValue[] = [];
  selectedOperationStatus = '';
  bancaItaliaSentDate: Date | null = null;

  tableFilters: string[] = [];
  private allFilters: FilterDef[] = [];

  checkedIds: string[] = [];
  numChecked: number = 0;

  columnDefs: ColumnDef[] = [
    { field: 'brand', header: 'Brand' },
    { field: 'shipCode', header: 'Ship' },
    { field: 'cruiseDate', header: 'Cruise Date', type: 'Date' },
    { field: 'paxId', header: 'Pax ID' },
    { field: 'name', header: 'Name' },
    { field: 'surname', header: 'Surname' },
    { field: 'gender', header: 'Gender' },
    { field: 'score', header: 'Score', type: 'number' },
    { field: 'scoreStatus', header: 'Score Status' },
    { field: 'placeOfBirth', header: 'POB' },
    { field: 'dateOfBirth', header: 'DOB', type: 'Date' },
    { field: 'residenceCountry', header: 'Country of Residence' },
    { field: 'nationality', header: 'Nationality' },
    { field: 'isPep', header: 'Is PEP', type: 'boolean' },
    { field: 'taxId', header: 'Tax ID' },
    { field: 'embDate', header: 'Embark Date', type: 'Date' },
    { field: 'disDate', header: 'Disembark Date', type: 'Date' },
    { field: 'bookingNumber', header: 'Booking No.' },
    { field: 'cruiseItineraryId', header: 'Itinerary ID' },
    { field: 'documentType', header: 'Doc. Type' },
    { field: 'documentNumber', header: 'Doc. Number' },
    { field: 'documentIssueDate', header: 'Doc. Issue Date', type: 'Date' },
    { field: 'documentIssuePlace', header: 'Doc. Issue Place' },
    { field: 'documentExpiryDate', header: 'Doc. Expiry Date', type: 'Date' },
    { field: 'dueDiligence', header: 'Due Diligence', type: 'boolean' },
    { field: 'riskyBehaviors', header: 'Risky Behaviors', type: 'boolean' },
    { field: 'riskyTransactions', header: 'Risky Transactions', type: 'boolean' },
    { field: 'riskyCountry', header: 'High Risk Country', type: 'boolean' },
    { field: 'operationStatus', header: 'Operations Status' },
    { field: 'bancaItaliaSentDate', header: 'Operation Date', type: 'Date' },
    { field: 'operationUserID', header: 'User ID' },
    { field: 'notes', header: 'Note' },
  ];
  sortColumns = ['surname', 'name'];

  ngOnInit(): void {
    this.dataService.getOperationStatus().subscribe(x => {
      this.operationStatus = x;
    });
    this.setFilters()
    this.getData();
    this.getColumnStatus(this.columnDefs);
  }

  private getData(alertMessage?: string) {
    this.messageService.waiting();

    this.dataService.getSuspiciousTrx(1, this.sortColumns, this.allFilters).subscribe(x => {
      this.checkedIds = [];
      this.numChecked = 0;
      this.table.initData(x[0], x[1]);
      if (alertMessage) {
        this.messageService.alert(alertMessage);
      } else {
        this.messageService.endWaiting();
      }
    });
  }

  protected fetchData = (page: number): Observable<object[]> => {
    return this.dataService.getSuspiciousTrx(page, this.sortColumns, this.allFilters).pipe(
      map(x => x[0])
    );
  }

  onButtonSearch() {
    this.setFilters();
    this.getData();
  }

  onTableChecksChange() {
    this.numChecked = this.checkedIds.length;
  }

  onTableLayoutChange() {
    this.saveColumnStatus(this.columnDefs);
  }

  onTableFilterChange() {
    this.setFilters();
    this.getData();
  }

  onTableSortChange() {
    if (this.sortColumns.length == 0) {
      this.sortColumns.push('surname');
      this.sortColumns.push('name');
    }
    this.getData();
  }

  onTableRowClick([row, cellIndex]: [object, number]) {
    const data = row as PaxDrillDown;
    this.paxPopup.id = data.id;
    this.paxPopup.name = data.name;
    this.paxPopup.surname = data.surname;
    this.paxPopup.visible = true;
  }

  onButtonApply() {
    this.messageService.confirm(
      'Operation Status',
      `Are you sure to change status of the ${this.numChecked} selected pax`,
      [['No', null], ['Yes', () => {
        this.messageService.waiting();

        this.dataService.setPax(this.checkedIds, { bancaItaliaSentDate: this.bancaItaliaSentDate! }).subscribe(() => {
          this.getData('Status successfully changed');
        });
      }]]
    );
  }

  onOperationStatusChange(value: string) {
    this.selectedOperationStatus = value;
    this.bancaItaliaSentDate = new Date();
  }

  onPaxPopupDataChange() {
    this.getData();
  }

  private setFilters() {
    this.allFilters = [
      ['scoreStatus', 'Equal', 'Suspicious'],
      ['operationStatus', 'Equal', '-'],
      ...this.getTableFilters(this.tableFilters, this.columnDefs)
    ];
  }

}
