<div class="p-1 w-full">
  <label class="block ml-3 font-medium text-base text-gray-600">
    {{label}}
  </label>

  <input type="text" #input
    class="block w-full px-3 py-2
          border rounded-lg border-gray-300
          font-normal text-sm text-gray-900
          focus:outline-none focus:border-yellow-300 focus:ring-2 focus:ring-yellow-200"
    [placeholder]="placeholder"
    [formControl]="formControl"
    (keyup.enter)="onEnter()">
</div>
