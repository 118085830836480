import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storageMap: Map<string, any>;

  constructor() {
    this.storageMap = new Map<string, any>();
   }

  setItem(key: string, value: any): void {
    this.storageMap.set(key, value);
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem<T>(key: string): T | null {
    let value: any;

    if (this.storageMap.has(key)) {
      value = this.storageMap.get(key);
    }
    else {
      let v = localStorage.getItem(key);
      if (v == null) {
        value = null;
      } else {
        value = JSON.parse(v);
        this.storageMap.set(key, value);
      }
    }

    return value;
  }
}
