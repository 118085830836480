import { CommonModule, formatDate } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { InputComponent } from '../input/input.component';
import { ButtonComponent } from '../button/button.component';
import { TableFilterComponent } from './table-filter.component';
import { Observable, filter, fromEvent, map } from 'rxjs';


export type TableData = string | number | boolean | Date | null;
export type TableDataType = 'string' | 'number' | 'boolean' | 'Date';

export type ColumnDef = {
  field: string,
  header: string,
  format?: string,
  noFilter?: boolean,
  type?: TableDataType,
  visible?: boolean
}


@Component({
  selector: 'app-table',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonComponent, DropdownComponent, InputComponent, TableFilterComponent],
  templateUrl: './table.component.html',
  styles: '.headerHover {color: inherit;}'
})
export class TableComponent implements OnChanges, AfterViewInit {
  @Input() columnDefs: ColumnDef[] = [];
  @Input() columnPicker: boolean = false;
  @Input() statusBar: boolean = false;
  @Input() data: object[] = [];
  @Input() idField?: string;
  @Input() checkedIds?: string[];
  @Input() checkable?: (data: object) => boolean;
  @Input() totalRows: number = 0;
  @Input() filters: string[] = [];
  @Input() sortColumns: string[] = [];
  @Input() fetchData?: (page: number) => Observable<object[]>;
  @Output() checksChange = new EventEmitter<[object, boolean][]>();
  @Output() filterChange = new EventEmitter<number>();
  @Output() layoutChange = new EventEmitter<void>();
  @Output() sortChange = new EventEmitter<void>();
  @Output() cellClick = new EventEmitter<[object, number]>();

  @ViewChild('scrollArea') scrollArea!: ElementRef;
  @ViewChild('thead') thead!: ElementRef<HTMLTableSectionElement>;
  @ViewChild('columnPickerButton') columnPickerButton!: ElementRef;

  protected checkAll = false;
  protected has2ndHeader = false;
  protected columnPickerMenu = { visible: false };

  protected currentFilter: {
    anchor?: Element,
    index: number,
    type: TableDataType,
    value: string,
    visible: boolean
  } = { index: -1, value: '', type: 'string', visible: false };

  protected rows: {
    checked: boolean,
    checkable: boolean,
    data: {[key: string]: TableData},
    cells: TableData[]
  } [] = [];

  public refreshRow(data: object) {
    const d = data as {[key: string]: TableData};
    if (this.idField) {
      const i = this.rows.findIndex(x => x.data[this.idField!] == d[this.idField!]);
      if (i != -1) {
        this.rows[i].checked = !!this.checkedIds && !!this.idField && this.checkedIds.includes(d[this.idField] as string),
        this.rows[i].checkable = this.checkable ? this.checkable(d) : true,
        this.rows[i].data = d;
        this.rows[i].cells = this.calcCells(d);
      }
    }
  }

  public refreshChecks() {
    this.rows.forEach(x => {
      x.checked = !!this.checkedIds && !!this.idField && this.checkedIds.includes(x.data[this.idField] as string);
      x.checkable = this.checkable ? this.checkable(x.data) : true;
    });
  }

  private calcRows(data: {[key: string]: TableData}[]) {
    this.rows = data.map(x => ({
      checked: !!this.checkedIds && !!this.idField && this.checkedIds.includes(x[this.idField] as string),
      checkable: this.checkable ? this.checkable(x) : true,
      data: x,
      cells: this.calcCells(x)
    }));
  }

  private calcCells(data: {[key: string]: TableData}): TableData[] {
    const cells = Array<TableData>(this.columnDefs.length);

    for (let i = 0; i < this.columnDefs.length; i++) {
      if (data.hasOwnProperty(this.columnDefs[i].field)) {
        cells[i] = this.format(data[this.columnDefs[i].field], this.columnDefs[i].format);
      }
    }

    return cells;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !this.fetchData) {
      this.calcRows(this.data as {[key: string]: TableData}[]);
      this.totalRows = this.data.length;
    }

    if (changes['columnDefs'] || changes['filters']) {
      this.filters.splice(0, this.filters.length);
      this.filters.push(...this.columnDefs.map(() => ''));
    }

    this.has2ndHeader = (this.filterChange.observed || this.sortChange.observed);
  }

  protected onClick(rowIndex: number, cellIndex: number) {
    this.cellClick.emit([this.rows[rowIndex].data, cellIndex]);
  }

  protected onCheckAllChange(value: boolean) {
    this.checkAll = value;
    //TODO  this.checks!.fill(value);
    //TODO  this.checksChange.emit(this.checks!.filter(x => x).length);
  }

  protected onCheckChange(rowIndex: number, value: boolean) {
    this.rows[rowIndex].checked = value;

    if (this.checkedIds && this.idField) {
      const rowData = this.rows[rowIndex].data;
      if (rowData.hasOwnProperty(this.idField)) {
        const idValue = rowData[this.idField] as string;
        const i = this.checkedIds.indexOf(idValue);
        if (value && i == -1) {
          this.checkedIds.push(idValue);
        }
        if (!value && i != -1) {
          this.checkedIds.splice(i, 1);
        }
      }

      //TODO this.checkAll = this.checks!.every(x => x);
      this.checksChange.emit([[rowData, value]]);

      if (this.checkable) {
        setTimeout(() => {
          this.rows.forEach(x => { x.checkable = this.checkable!(x.data) })
        }, 100);
      }
    }
  }

  private format(data: TableData, format?: string): TableData {
    if (data instanceof Date) {
      if (format) {
        return formatDate(data, format, 'en')
      } else {
        return formatDate(data, 'dd/MM/yyyy', 'en')
      }
    }
    if (typeof data == 'boolean') {
      if (format) {
        return data ? format.split('|')[0] : format.split('|')[1]??'';
      } else {
        return data ? 'Yes' : 'No';
      }
    }
    return data;
  }

  private getTh2Element(index: number): Element {
    const th2Index = this.columnDefs.filter((x, i) => i < index)
                                    .filter(x => x.visible == undefined || x.visible).length
                                    + (this.checkedIds ? 1 : 0);
    return this.thead.nativeElement.children[1].children[th2Index] as Element;
  }

  protected onHeaderHover(index: number, enter: boolean) {
    if (this.has2ndHeader) {
      const th2 = this.getTh2Element(index);
      if (enter) {
        th2.classList.add('headerHover');
      } else {
        th2.classList.remove('headerHover');
      }
    }
  }

  protected onColumnPickerItemChange(index: number, value: boolean) {
    this.columnDefs[index].visible = value;
    this.layoutChange.emit();
  }

  protected onFilterClick(index: number) {
    if (this.currentFilter.visible && this.currentFilter.index == index) {
      this.currentFilter.visible = false;
    } else {
      this.currentFilter = {
        anchor: this.getTh2Element(index),
        index: index,
        type: this.columnDefs[index].type ?? 'string',
        value: ' ',
        visible: true
      };
      // non si sa per quale motivo il changeDetector non rileva subito la variazione di value
      // quindi viene prima impostato a spazio (non stringa vuota) e poi con il valore corretto
      setTimeout(() => { this.currentFilter.value = this.filters[index] }, 10);
    }
  }

  protected onFilterChange(value: string) {
    if (value != this.filters[this.currentFilter.index]) {
      this.filters[this.currentFilter.index] = value;
      this.filterChange.emit(this.currentFilter.index);
    }
    this.currentFilter.visible = false;
  }

  protected onSortChange(index: number, e: Event) {

    let prevIndex = this.sortColumns.indexOf(this.columnDefs[index].field);
    if (prevIndex >= 0) {
      this.sortColumns[prevIndex] = '-' + this.sortColumns[prevIndex];   // descending
    } else {
      prevIndex = this.sortColumns.indexOf('-' + this.columnDefs[index].field);
      if (prevIndex >= 0) {
        this.sortColumns.splice(prevIndex, 1);
      } else {
        this.sortColumns.push(this.columnDefs[index].field);
      }
    }

    this.sortChange.emit();
  }

  //////////// scrolling //////////////
  private lastScrollTop = 0;
  dataPages: {[key: string]: TableData}[][] = [];
  dataPageIndex = 0;  // numero di pagina del primo elemento di dataPages
  dataAtEnd = false;
  scrolling = false;

  public initData(data: {[key: string]: TableData}[], totalRows: number) {
    this.lastScrollTop = 0;
    this.scrollArea.nativeElement.scrollTop = 0;

    this.dataPageIndex = 1;
    this.dataPages = [[...data]];
    this.dataAtEnd = (data.length == 0);
    this.totalRows = totalRows;
    setTimeout(() => { this.calcRows(data) }, 10);
  }

  ngAfterViewInit(): void {
    if (this.fetchData) {
      fromEvent<Event>(this.scrollArea.nativeElement, 'scroll').pipe(
        map(e => e.target as HTMLElement),
        filter(e => e.scrollTop != this.lastScrollTop),
        map(e => {
          let scrollDown = (e.scrollTop > this.lastScrollTop);
          let toScroll = scrollDown ? e.scrollTop > e.scrollHeight * 2/3 || this.dataPages.length < 3
                                    : e.scrollTop < e.scrollHeight * 1/3;
          this.lastScrollTop = e.scrollTop;
          return [scrollDown, toScroll];
        }),
        filter(([scrollDown, toScroll]) => !this.scrolling && toScroll)
      ).subscribe(([scrollDown, toScroll]) => this.onScroll(scrollDown));
    }
  }

  protected onScroll(scrollDown: boolean) {
    if ((scrollDown && !this.dataAtEnd) || (!scrollDown && this.dataPageIndex > 1)) {
      this.scrolling = true;
      this.callFetchData(scrollDown).subscribe(atEnd => {
        if (!atEnd) {
          this.calcRows(([] as {[key: string]: TableData}[]).concat(...this.dataPages));
        }
        this.dataAtEnd = atEnd;
        this.scrolling = false;
      });
    }
  }

  private callFetchData(next: boolean = true): Observable<boolean> {
    let page = next ? this.dataPageIndex + this.dataPages.length : this.dataPageIndex - 1;

    return this.fetchData!(page).pipe(
      map(data => {
        if (data.length == 0) {
          return true;
        }

        if (next) {
          if (this.dataPages.length == 3) {
            this.dataPageIndex++;
            this.dataPages.shift();
          }
          this.dataPages.push([...(data as {[key: string]: TableData}[])]);

        } else {
            this.dataPageIndex--;
            this.dataPages.unshift([...(data as {[key: string]: TableData}[])]);
            if (this.dataPages.length > 3) {
              this.dataPages.pop();
            }
        }
        return false;
      })
    );
  }
}
