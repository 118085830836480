import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styles: ``
})
export class ButtonComponent {
  @Input() disabled: boolean = false;
  @Input() size: 'small' | 'normal' = 'normal';
  @Input() type: 'standard' | 'icon' = 'standard';
  @Input() shape: 'standard' | 'outline' = 'standard';
}
