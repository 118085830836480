import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { I18nService } from '../i18n.service';
import { environment } from '../../../environments/environment';
import { MessageService } from '../message/message.service';

type BeResponse = {
  data: any | null,
  result: {
    error: string | null,
    message: string
  } | null
}

@Injectable({
  providedIn: 'root',
})
export class BackendService {

  constructor(
    private httpClient: HttpClient,
    private i18n: I18nService,
    private messageService: MessageService
    ) { }


  public httpGet<T>(apiName: string, query: object = {}): Observable<T> {
    return this.get<T>(apiName, query, 1) as Observable<T>;
  }

  public httpGet2<T>(apiName: string, query: object = {}): Observable<[T, number]> {
    return this.get<T>(apiName, query, 2) as Observable<[T, number]>;
  }

  private get<T>(apiName: string, query: object, numResponse: number): Observable<T | [T, number]> {
    let queryParams = new HttpParams();
    for (const entry of Object.entries(query)) {
      queryParams = queryParams.append(entry[0], entry[1]);
    }
    let url = `${environment.apiConfig.url}/api/${apiName}?${queryParams.toString()}`;

    return this.httpClient.get<BeResponse>(url).pipe(
      map(x => this.parseResponse<T>(x, numResponse)),
      catchError(error => {
        this.messageService.confirm('Error', error.message);
        throw error;
      })
    );
  }

  public httpGetBinary(apiName: string, query: object = {}): Observable<{file: Blob, filename: string}> {
    let queryParams = new HttpParams();
    for (const entry of Object.entries(query)) {
      queryParams = queryParams.append(entry[0], entry[1]);
    }
    let url = `${environment.apiConfig.url}/api/${apiName}?${queryParams.toString()}`;

    return this.httpClient.get<Blob>(
      url,
      { observe: 'response', responseType: 'blob' as 'json' }
    ).pipe(
      map((response: HttpResponse<Blob>) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition
                           ? (contentDisposition.split(';')[1].split('=')[1].trim())??''
                           : '';
        return { file: response.body!, filename };
      }),
      catchError(error => {
        this.messageService.confirm('Error', error.message);
        throw error;
      })
    );
  }

  public httpPost<T>(apiName: string, payload: object): Observable<T> {
    return this.post<T>(apiName, payload, 1) as Observable<T>;
  }

  public httpPost2<T>(apiName: string, payload: object): Observable<[T, number]> {
    return this.post<T>(apiName, payload, 2) as Observable<[T, number]>;
  }

  private post<T>(apiName: string, payload: object, numResponse: number): Observable<T | [T, number]> {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    let url = `${environment.apiConfig.url}/api/${apiName}`;

    return this.httpClient.post<BeResponse>(url, payload, { headers: headers }).pipe(
      map(x => this.parseResponse<T>(x, numResponse)),
      catchError(error => {
        this.messageService.confirm('Error', error.message);
        throw error;
      })
    );
  }

  public httpPostBinary(apiName: string, payload: object): Observable<{file: Blob, filename: string}> {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    let url = `${environment.apiConfig.url}/api/${apiName}`;

    return this.httpClient.post<Blob>(
      url, payload,
      { headers: headers, observe: 'response', responseType: 'blob' as 'json' }
    ).pipe(
      map((response: HttpResponse<Blob>) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition
                           ? (contentDisposition.split(';')[1].split('=')[1].trim())??''
                           : '';
        return { file: response.body!, filename };
      }),
      catchError(error => {
        this.messageService.confirm('Error', error.message);
        throw error;
      })
    );
  }

  public httpPut<T>(apiName: string, payload: object): Observable<T> {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    let url = `${environment.apiConfig.url}/api/${apiName}`;

    return this.httpClient.put<BeResponse>(url, payload, { headers: headers }).pipe(
      map(x => this.parseResponse<T>(x, 1) as T),
      catchError(error => {
        this.messageService.confirm('Error', error.message);
        throw error;
      })
    );
  }

  private parseResponse<T>(response: BeResponse, numResponse: number): T | [T, number] {
    if (response.result != null && response.result.error != null) {
      throw new Error(`${response.result.error} - ${response.result.message}`);
    }

    if (response.data == null) {
      return null as T;
    }

    if (response.data.hasOwnProperty('list')) {
      if (numResponse == 1) {
        return response.data.list.map((x: { [key: string]: any; }) => this.parseObject(x)) as T;
      } else {
        return [response.data.list.map((x: { [key: string]: any; }) => this.parseObject(x)) as T,
                response.data.hasOwnProperty('totalElements') ? response.data.totalElements as number : 0];
      }
    }

    if (Array.isArray(response.data)) {
      return numResponse == 1
           ? response.data as T
           : [response.data as T, 0];
    }

    return this.parseObject(response.data) as T;
  }

  private parseObject(o: {[key: string]: any}): object {
    for (const key in o) {
      const value = o[key];
      if (this.i18n.isIsoDateString(value)) {
        o[key] = new Date(value);
      }
    }

    return o;
  }

}
