import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private routeChange$: Subject<string> = new Subject<string>();
  routeChange = this.routeChange$.asObservable();

  constructor(private router: Router) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.routeChange$.next(e.url.substring(1));
      }
    });
  }

  public go(route: string): void {
    this.router.navigate([route], { replaceUrl: true });
  }

  public currentRoute(): string {
    //return location.pathname.substring(1);
    return this.router.url.substring(1);
  }
}
