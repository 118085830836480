import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './select.component.html',
  styles: ``
})
export class SelectComponent implements OnInit, OnChanges {
  @Input() label: string = '';
  @Input() selectedKey: string = '';
  @Input() items: {key: string, value: string }[] = [];
  @Input() placeholder: string = '';
  @Input() cancellable: boolean = false;
  @Input() readonly: boolean = false;
  @Output() selectedKeyChange = new EventEmitter<string>();

  @ViewChild('input') input!: ElementRef;
  @ViewChild('content') content!: ElementRef;
  keepFocus: boolean = false;
  selectedValue: string = '';
  showContent: boolean = false;

  inputRect!: DOMRect;
  contentRect!: DOMRect;

  ngOnInit(): void {
    this.setSelectedValue(this.selectedKey);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSelectedValue(this.selectedKey);
  }

  private setSelectedValue(key: string) {
    const i = this.items.find(x => x.key == this.selectedKey);
    this.selectedValue = i ? i.value : '';
  }

  onInputClick() {
    this.onClick();
    this.showContent = !this.readonly && !this.showContent;
    if (this.showContent) {
      setTimeout(() => {
        this.inputRect = this.input.nativeElement.getBoundingClientRect();
        this.contentRect = this.content.nativeElement.getBoundingClientRect();
      }, 200);
    }
  }

  onCancelClick() {
    this.onClick();
    this.selectedKey = '';
    this.selectedValue = '';
    this.showContent = false;
    this.selectedKeyChange.emit('');
  }

  onInputFocus() {
    this.keepFocus = true;
  }

  onInputBlur() {
    this.keepFocus = false;
    setTimeout(() => {
      if (!this.keepFocus) {
        this.showContent = false;
      }
    }, 200);
  }

  onClick() {
    this.keepFocus = true;
    this.input.nativeElement.focus();  // rimetto il focus sul bottone
  }

  onItemClick(key: string) {
    this.onClick();
    this.selectedKey = key;
    this.setSelectedValue(key);
    this.showContent = false;
    this.selectedKeyChange.emit(key);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {   // nasconde il content se mi allontano con il mouse
    if (   this.showContent
        && this.contentRect != undefined
        && (   e.clientX < this.inputRect.left - 40
            || e.clientX > this.inputRect.right + 40
            || e.clientY < this.inputRect.top - 40
            || e.clientY > this.inputRect.bottom + 40)
        && (   e.clientX < this.contentRect.left - 40
            || e.clientX > this.contentRect.right + 40
            || e.clientY < this.contentRect.top - 40
            || e.clientY > this.contentRect.bottom + 40)) {

      this.showContent = false;
    }
  }

}
