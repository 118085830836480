import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MainModule } from '../../main.module';
import { CommonModule } from '@angular/common';
import { DataService } from '../../../services/data/data.service';
import { KeyValue, PaxBehavior, PaxDocument, PaxTransaction, Pax } from '../../../services/data/data.model';
import { ColumnDef } from '../../../shared/components/table/table.component';
import { AuthService } from '../../../services/auth.service';
import { appFunctions } from '../../../app.model';

type DataChanged = {
  operationStatusId: string,
  bancaItaliaSentDate: Date | null,
  notes: string,
  vcStatusId: string
}

@Component({
  selector: 'app-pax-popup',
  standalone: true,
  imports: [CommonModule, MainModule],
  templateUrl: './pax-popup.component.html',
  styles: ``
})
export class PaxPopupComponent implements OnInit, OnChanges {
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() surname: string = '';
  @Input() visible: boolean = false;
  @Output() dataChange = new EventEmitter<void>();
  @Output() visibleChange = new EventEmitter<boolean>();

  isOperationStatusEnabled = this.authService.isEnabled(appFunctions.Paxes_MassiveUpdate_UIF);
  isVcFeedbackEnabled = this.authService.isEnabled(appFunctions.Paxes_MassiveUpdate_VCFeedback);
  isNoteEnabled = this.authService.isEnabled(appFunctions.Paxes_MassiveUpdate_Notes);
  isPaxDocumentEnabled = this.authService.isEnabled(appFunctions.Paxes_Document);

  tabIndex = 0;
  tabs = ['Pax', 'Behavior', 'Transactions'];

  pax: Pax = {} as Pax;
  dataChanged = {} as DataChanged;

  paxDocument: PaxDocument = {} as PaxDocument;
  largeImage = false;

  behaviors: {columnDefs: ColumnDef[], data: PaxBehavior[]} = {
    columnDefs: [
      { field: 'description', header: 'Behavior Type' },
      { field: 'actualScore', header: 'Behavior Score' },
      { field: 'reporter', header: 'User ID' },
      { field: 'notes', header: 'Note' },
      { field: 'insertionDate', header: 'Operation Date', format: 'dd/MM/YYYY HH:mm' },
    ],
    data: []
  };

  transactions: {columnDefs: ColumnDef[], data: PaxTransaction[]} = {
    columnDefs: [
      { field: 'operationSource', header: 'Operation Source' },
      { field: 'operationDate', header: 'Operation Date', format: 'dd/MM/YYYY HH:mm' },
      { field: 'amount', header: 'Amount' },
      { field: 'paymentType', header: 'Payment Type' },
      { field: 'transactionId', header: 'Transaction ID' },
      { field: 'bankId', header: 'Bank ID' },
      { field: 'authorizer', header: 'Authorizer' },
      { field: 'transType', header: 'Transaction Type' },
      { field: 'itemCode', header: 'Item' },
      { field: 'osId', header: 'OS ID' },
      { field: 'gmDayId', header: 'GM Day' },
      { field: 'flag', header: 'Flag' },
      { field: 'userId', header: 'User' },
      { field: 'status', header: 'Status' },
      { field: 'transactionLocation', header: 'Location' },
      { field: 'agentPlayerId', header: 'Agent Player' },
    ],
    data: []
  };

  operationStatus: KeyValue[] = [];
  vcFeedback: KeyValue[] = [];

  scores = [
    { name: 'Suspicious Trx', value: 0, color: 'orange', totalBefore: 0, totalAfter: 0 },
    { name: 'High Risk Country', value: 0, color: 'limegreen', totalBefore: 0, totalAfter: 0 },
    { name: 'PEP', value: 0, color: 'peru', totalBefore: 0, totalAfter: 0 },
    { name: 'Risky Behavior', value: 0, color: 'blue', totalBefore: 0, totalAfter: 0 },
    { name: 'Visual Compliance', value: 0, color: 'gold', totalBefore: 0, totalAfter: 0 },
  ];
  totalScore = { threshold: 100, position: 0, value: 0 };

  loading = true;
  loadingScores = true;
  loadingDoc = true;

  constructor(
    protected authService: AuthService,
    private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getOperationStatus().subscribe(x => { this.operationStatus = x; });
    this.dataService.getVCFeedback().subscribe(x => { this.vcFeedback = x; });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible'] && this.visible) {
      this.init();
    }
  }

  private init() {
    this.loading = true;
    this.loadingScores = true;
    this.loadingDoc = true;
    this.tabIndex = 0;
    this.pax = {} as Pax;
    this.dataChanged = {} as DataChanged;
    this.paxDocument = {} as PaxDocument;
    this.largeImage = false;
    this.behaviors.data = [];
    this.transactions.data = [];
    this.totalScore = { threshold: 100, position: 0, value: 0 };

    this.dataService.getPax(this.id).subscribe(x => {
      this.loading = false;
      this.pax = x;
      this.dataChanged.operationStatusId = x.operationStatusId;
      this.dataChanged.bancaItaliaSentDate = x.bancaItaliaSentDate ? new Date(x.bancaItaliaSentDate.getTime()) : null;
      this.dataChanged.notes = x.notes;
      this.dataChanged.vcStatusId = x.vcStatusId;
    });


    this.dataService.getPaxScores(this.id).subscribe(x => {
      this.scores[0].value = x.suspiciosTransactions;
      this.scores[1].value = x.riskyCountry;
      this.scores[2].value = x.pep;
      this.scores[3].value = x.riskyBehaviors;
      this.scores[4].value = x.others;

      this.totalScore.threshold = x.threshold;
      this.totalScore.value = this.scores.reduce((sum, x) => sum + x.value, 0);
      this.totalScore.position = Math.min(this.totalScore.value / this.totalScore.threshold, 2) * 50;

      let runningTotal = 0;
      for (let i = 0; i < this.scores.length; i++) {
        this.scores[i].totalBefore = runningTotal;
        this.scores[i].totalAfter = this.totalScore.value - runningTotal - this.scores[i].value;
        runningTotal += this.scores[i].value;
      }
      this.loadingScores = false;
    });


    if (this.isPaxDocumentEnabled) {
      this.dataService.getPaxDocument(this.id).subscribe(x => {
        this.loadingDoc = false;
        this.paxDocument = x ?? {} as PaxDocument;
      });
    } else {
      this.loadingDoc = false;
      this.paxDocument = {} as PaxDocument;
    }

    this.dataService.getPaxRiskyBehaviors(this.id).subscribe(x => { this.behaviors.data = x });
    this.dataService.getPaxTransactions(this.id).subscribe(x => { this.transactions.data = x });
  }

  onButtonClick(button: number) {
    if (button == 1) {
      let values: {
        bancaItaliaSentDate?: Date,
        notes?: string,
        vcStatusId?: string
      } = {};

      if ((this.dataChanged.bancaItaliaSentDate ? this.dataChanged.bancaItaliaSentDate.getTime() : 0) !=
          (this.pax.bancaItaliaSentDate ? this.pax.bancaItaliaSentDate.getTime() : 0)) {
        if (this.dataChanged.bancaItaliaSentDate) {
          values.bancaItaliaSentDate = this.dataChanged.bancaItaliaSentDate;
        } else {
          let d = new Date(1, 0, 1);   // per il BE significa null
          d.setFullYear(1);
          values.bancaItaliaSentDate = d;
        }
      }

      if (this.dataChanged.notes != this.pax.notes) {
        values.notes = this.dataChanged.notes;
      }

      if (this.dataChanged.vcStatusId != this.pax.vcStatusId) {
        values.vcStatusId = this.dataChanged.vcStatusId;
      }

      if (Object.keys(values).length > 0) {
        this.dataService.setPax([this.id], values).subscribe(() => {
          this.dataChange.emit();
        });
      }
    }
  }

  onVisibleChange(value: boolean) {
    this.visible = value;
    this.visibleChange.emit(value);
  }

  onOperationStatusChange(value: string) {
    this.dataChanged.operationStatusId = value;
    this.dataChanged.bancaItaliaSentDate = value == '' ? null : new Date();
  }

}
