import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { IconComponent } from './components/icon/icon.component';
import { InputComponent } from './components/input/input.component';
import { ListComponent } from './components/list/list.component';
import { MenuComponent } from './components/menu/menu.component';
import { SelectComponent } from './components/select/select.component';
import { TableComponent } from './components/table/table.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { InputNumberComponent } from './components/input-number/input-number.component';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ButtonComponent,
    InputDateComponent,
    DialogComponent,
    DropdownComponent,
    IconComponent,
    InputComponent,
    InputNumberComponent,
    ListComponent,
    MenuComponent,
    SelectComponent,
    YesNoPipe,
    TableComponent,
    TextareaComponent
  ],
  exports: [
    ButtonComponent,
    InputDateComponent,
    DialogComponent,
    DropdownComponent,
    IconComponent,
    InputComponent,
    InputNumberComponent,
    ListComponent,
    MenuComponent,
    SelectComponent,
    YesNoPipe,
    TableComponent,
    TextareaComponent
  ]
})
export class SharedModule { }
