import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '../base-page';
import { FilterDef, KeyValue, PaxDrillDown } from '../../../services/data/data.model';
import { CommonModule } from '@angular/common';
import { MainModule } from '../../main.module';
import { ColumnDef, TableComponent } from '../../../shared/components/table/table.component';
import { PaxPopupComponent } from '../pax-popup/pax-popup.component';
import { Observable, map } from 'rxjs';
import { appFunctions } from '../../../app.model';

@Component({
  selector: 'app-pep-approval',
  standalone: true,
  imports: [CommonModule, MainModule, PaxPopupComponent],
  templateUrl: './pep-approval.component.html',
  styles: ``
})
export class PepApprovalComponent extends BasePage implements OnInit {
  constructor(private injector: Injector) { super(injector, 'pep-approval'); }

  @ViewChild('table') table!: TableComponent;


  isVCFeedbackEnabled = this.authService.isEnabled(appFunctions.Paxes_MassiveUpdate_VCFeedback);
  isApproveEnabled = this.authService.isEnabled(appFunctions.PEPApprovals_MassiveUpdate_Approve);
  isBlockEnabled = this.authService.isEnabled(appFunctions.PEPApprovals_MassiveUpdate_NotApprove);
  isReadOnly = !this.isApproveEnabled && !this.isBlockEnabled;

  AppFunctions = appFunctions;

  fromDate!: Date;
  toDate!: Date;
  tableFilters: string[] = [];
  private allFilters: FilterDef[] = [];
  checkedIds: string[] = [];
  numChecked: number = 0;
  checksApproveType: 'Approve' | 'Block' | '' = '';
  checksSentToVC: boolean = false;

  paxPopup = {id: '', name: '', surname: '', visible: false};

  menuItems = [{
    key: '1',
    value: 'Export to Excel',
    icon: 'download',
    disabled: !this.authService.isEnabled(appFunctions.PEPApprovals_Export) },
  ];

  columnDefs: ColumnDef[] = [
    { field: 'paxId', header: 'Pax ID' },
    { field: 'name', header: 'Name' },
    { field: 'surname', header: 'Surname' },
    { field: 'gender', header: 'Gender' },
    { field: 'placeOfBirth', header: 'POB' },
    { field: 'dateOfBirth', header: 'DOB', type: 'Date' },
    { field: 'residenceCountry', header: 'Country of Residence' },
    { field: 'nationality', header: 'Nationality' },
    { field: 'isPep', header: 'Is PEP', type: 'boolean' },
    { field: 'taxId', header: 'Tax ID' },
    { field: 'embDate', header: 'Embark Date', type: 'Date' },
    { field: 'disDate', header: 'Disembark Date', noFilter: true },
    { field: 'isPEPApproved', header: 'Is Approved', type: 'boolean' },
    { field: 'isPEPApprovalPending', header: 'Approval Status', type: 'boolean', format: 'Pending|Confirmed' },
    { field: 'approvedBy', header: 'Approved By' },
    { field: 'approvalTimeStamp', header: 'Approve Date', type: 'Date' },
    { field: 'bookingNumber', header: 'Booking No.' },
    { field: 'cruiseItineraryId', header: 'Itinerary ID' },
    { field: 'documentType', header: 'Doc. Type' },
    { field: 'documentNumber', header: 'Doc. Number' },
    { field: 'documentIssueDate', header: 'Doc. Issue Date', type: 'Date' },
    { field: 'documentIssuePlace', header: 'Doc. Issue Place' },
    { field: 'documentExpiryDate', header: 'Doc. Expiry Date', type: 'Date' },
  ];
  sortColumns = ['surname', 'name'];

  vcFeedback: KeyValue[] = [];

  ngOnInit(): void {
    this.fromDate = this.i18n.today(-28);
    this.toDate = this.i18n.today(28);

    this.setFilters();
    this.getData();
    this.dataService.getVCFeedback().subscribe(x => {
      this.vcFeedback = x.sort((a, b) => a.value.localeCompare(b.value));
    });
    this.getColumnStatus(this.columnDefs);
  }

  private getData(alertMessage?: string) {
    this.messageService.waiting()

    this.dataService.getPep(1, this.sortColumns, this.allFilters).subscribe(x => {
      this.checkedIds = [];
      this.numChecked = 0;
      this.checksApproveType = '';
      this.checksSentToVC = false;
      this.table.initData(x[0], x[1]);
      if (alertMessage) {
        this.messageService.alert(alertMessage);
      } else {
        this.messageService.endWaiting();
      }
    });
  }

  protected fetchData = (page: number): Observable<object[]> => {
    return this.dataService.getPep(page, this.sortColumns, this.allFilters).pipe(
      map(x => x[0])
    );
  }

  onButtonSearch() {
    this.setFilters();
    this.getData();
  }

  onButtonApprove(approve: boolean) {
    this.messageService.confirm(
      'PEP Approval',
      `Are you sure to ${approve ? 'approve' : 'block'} the ${this.numChecked} selected PEP`,
      [['No', null], ['Yes', () => {

        this.messageService.waiting();
        this.dataService.setPep(this.checkedIds, approve).subscribe(() => {
          this.getData(`The ${approve ? 'approval' : 'block'} request has been sent`);
        });
      }]]
    );
  }

  onButtonVCFeedback(index: number) {
    this.messageService.confirm(
      'VC Feedback',
      `Are you sure to set to ${this.vcFeedback[index].value} the VC Feedback for the ${this.numChecked} selected PEP`,
      [['No', null], ['Yes', () => {

        this.messageService.waiting();
        this.dataService.setPax(this.checkedIds, { vcStatusId: this.vcFeedback[index].key }).subscribe(() => {
          this.getData(`PEP updated successfully`);
        });
      }]]
    );
  }

  onMenuChoice(itemKey: string) {
    this.messageService.waiting();

    if (itemKey == '1') {
      this.dataService.getPepExcel(this.sortColumns, this.allFilters).subscribe(x => {
        this.messageService.endWaiting();
        this.dataService.saveAs(x.file, (x.filename ? x.filename : 'Export_PEPApprovals.zip'));
      });
    }
  }

  tableRowCheckable = (data: object): boolean => {
    // se ho già selezionato posso selezionare solo le righe con attributi omogenei
    return this.numChecked == 0
        || (    this.checksSentToVC == (data as PaxDrillDown).sentToVisualCompliance
            && (   (this.checksApproveType == 'Approve' && !(data as PaxDrillDown).isPEPApproved)
                || (this.checksApproveType == 'Block' && (data as PaxDrillDown).isPEPApproved)));
  }

  onTableChecksChange(rows: [object, boolean][]) {
    this.numChecked = this.checkedIds.length;
    this.checksSentToVC = this.numChecked > 0 && (rows[0][0] as PaxDrillDown).sentToVisualCompliance;
    this.checksApproveType = this.numChecked == 0
                    ? ''
                    : (rows[0][0] as PaxDrillDown).isPEPApproved
                    ? 'Block'
                    : 'Approve';
  }

  onTableFilterChange() {
    this.setFilters();
    this.getData();
  }

  onTableSortChange() {
    if (this.sortColumns.length == 0) {
      this.sortColumns.push('surname');
      this.sortColumns.push('name');
    }
    this.getData();
  }

  onTableLayoutChange() {
    this.saveColumnStatus(this.columnDefs);
  }

  onTableRowClick([row, cellIndex]: [object, number]) {
    const data = row as PaxDrillDown;
    this.paxPopup.id = data.id;
    this.paxPopup.name = data.name;
    this.paxPopup.surname = data.surname;
    this.paxPopup.visible = true;
  }

  onPaxPopupDataChange() {
    this.dataService.getPep(1, [], [['id', 'Equal', this.paxPopup.id]]).subscribe(([data, numRows]) => {
      if (numRows == 1) {
        const i = this.checkedIds.indexOf(this.paxPopup.id);
        if (i != -1) {
          if (this.numChecked == 1) {
            this.checksApproveType = data[0].isPEPApproved ? 'Block' : 'Approve';
          } else {
            if ((this.checksApproveType == 'Approve' && data[0].isPEPApproved)
             || (this.checksApproveType == 'Block' && !data[0].isPEPApproved)) {
              // tolgo il check se non è più compatibile con il tipo di azione
              this.checkedIds.splice(i, 1);
              this.numChecked--;
            }
          }
        }

        this.table.refreshRow(data[0]);
        this.table.refreshChecks();
      }
    });
  }

  private setFilters() {
    this.allFilters = [
      ['disDate', 'GreaterEqual', this.fromDate],
      ['disDate', 'LessEqual', this.toDate],
      ...this.getTableFilters(this.tableFilters, this.columnDefs)
    ];
  }

}
