<div class="m-1 text-base text-gray-900">
  <label class="block ml-3 font-medium text-gray-600">{{label}}</label>
  <div class="relative">
    <input type="text" readonly #input
      class="block w-full px-3 py-1.5
              border rounded-lg border-gray-300
              focus:outline-none focus:border-yellow-300 focus:ring-2 focus:ring-yellow-200"
      [ngClass]="{ 'outline-none border-yellow-300 ring-2 ring-yellow-200': showPicker }"
      [ngModel]="selectedDate.formatted"
      (click)="onInputClick()"
      (focus)="onInputFocus()"
      (blur)="onInputBlur()"
      [placeholder]="placeholder">

    <div class="absolute top-0 right-0 px-3 py-2 text-gray-500"
      (click)="onInputClick()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
      </svg>
    </div>

    @if(showPicker && !disabled) {
      <div class="bg-white mt-11 border border-gray-300 rounded-lg shadow-md px-4 py-3 absolute z-20 top-0 left-0 w-64"
           (click)="onClick()">
        <div class="flex justify-between items-center mb-2">
          <div>
            <span class="text-lg font-semibold text-gray-800">{{MONTH_NAMES[picker.month]}}</span>
            <span class="ml-1 text-lg text-gray-600 font-normal">{{picker.year}}</span>
          </div>

          <div class="text-gray-200">
              <div
                class="inline-flex p-1 rounded-full"
                [ngClass]="{ 'cursor-pointer hover:bg-gray-100': picker.year > from.year || picker.month > from.month }"
                (click)="onPrevMonthClick(picker.year > from.year || picker.month > from.month)">

                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"
                      [ngClass]="{ 'text-gray-500': picker.year > from.year || picker.month > from.month }"/>
                  </svg>
                </div>
              <div
                class="inline-flex p-1 rounded-full"
                [ngClass]="{ 'cursor-pointer hover:bg-gray-100': picker.year < to.year || picker.month < to.month }"
                (click)="onNextMonthClick(picker.year < to.year || picker.month < to.month)">

                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"
                    [ngClass]="{ 'text-gray-500': picker.year < to.year || picker.month < to.month }"/>
                </svg>
              </div>
          </div>
        </div>

        <div class="flex flex-wrap -mx-1">
            @for (day of DAY_NAMES; track $index) {
              <div style="width: 14.26%" class="px-1">
                <div class="text-gray-800 font-medium text-center text-xs">{{day}}</div>
              </div>
            }
        </div>

        <div class="flex flex-wrap -mx-1 mt-2">
          @for (blankDay of picker.blankDays; track $index) {
            <div style="width: 14.28%" class="text-center border p-1 border-transparent text-base"></div>
          }
          @for (day of picker.monthDays; track $index) {
            <div style="width: 14.28%;" class="px-1 mb-1">
              <div
                  (click)="isDateInRange(day) && onDayClick(day)"
                  class="text-center text-base rounded-full "
                  [ngClass]="{'text-gray-800 cursor-pointer hover:ring-2 hover:ring-yellow-200': isDateInRange(day),
                              'text-gray-200': !isDateInRange(day),
                              'bg-blue-900 text-white': isSelectedDate(day),
                              'hover:bg-yellow-200': isDateInRange(day) && !isSelectedDate(day) }">
                  {{day}}
              </div>
            </div>
          }

        </div>
      </div>
    }
  </div>
</div>
