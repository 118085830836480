@if (isReady && isAuthorized != undefined && isAuthorized) {

  <app-home></app-home>

} @else {

  <div class="flex flex-col justify-center items-center w-[100vw] h-[80vh]">
    <div class="text-gray-300 text-4xl font-bold">
      AML Collector
    </div>

    @if (isReady) {
      @if (isAuthorized == undefined) {
        <div>App temporarily unavailable.</div>
      } @else {
        <div>You do not have permission to use this app.</div>
      }
    }

  </div>

}

