import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from '../base-page';
import { MainModule } from '../../main.module';
import { DashboardData } from '../../../services/data/data.model';
import { appFunctions } from '../../../app.model';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-overall',
  standalone: true,
  imports: [CommonModule, MainModule],
  templateUrl: './overall.component.html',
  styles: ``
})
export class OverallComponent extends BasePage implements OnInit {

  constructor(private injector: Injector) { super(injector, 'overall'); }
  isReadOnly = true;

  data!: DashboardData;
  route = ['pep-approval', 'suspicious-trx', 'due-diligence-register'];
  routeFunctions = [
    appFunctions.PEPApprovals_Search,
    appFunctions.SuspiciousTransactions_Search,
    appFunctions.DueDiligence_Search
  ];
  routeEnabled = this.routeFunctions.map(x => this.authService.isEnabled(x));
  kpiFunctions = [
    appFunctions.Dashboard_GetCounts_PaxAdded,
    appFunctions.Dashboard_GetCounts_PaxUIF,
    appFunctions.Dashboard_GetCounts_PaxDueDiligence
  ];
  kpiEnabled = this.kpiFunctions.map(x => this.authService.isEnabled(x));
  numKpiEnabled = this.kpiEnabled.filter(x => x).length;

  ngOnInit(): void {
    this.messageService.waiting()
    this.dataService.getDashboard().subscribe(x => {
      this.data = x;
      this.messageService.endWaiting();
    });
  }

  onClick(cardNo: number) {
    if (this.routeEnabled[cardNo]) {
      this.navigationService.go(this.route[cardNo]);
    }
  }
}
