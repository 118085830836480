<div class="p-1 h-full flex flex-col">
  <label class="ml-3 font-medium text-gray-600">{{label}}</label>

  <textarea
    class="px-3 py-2 resize-none flex-grow
          border rounded-lg border-gray-300
          text-sm text-gray-900
          focus:outline-none focus:border-yellow-300 focus:ring-2 focus:ring-yellow-200"
    [placeholder]="placeholder"
    [readOnly]="readonly"
    [ngModel]="value"
    (ngModelChange)="onChange($event)">
  </textarea>
</div>
