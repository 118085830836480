import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dropdown.component.html',
  styles: ``
})
export class DropdownComponent implements OnChanges {
  @Input() anchor!: Element | ElementRef;
  @Input() autoClose: boolean = true;
  @Input() position: 'left' | 'right' = 'left';
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  @ViewChild('content') content!: ElementRef;
  anchorRect!: DOMRect;
  contentRect!: DOMRect;

  positionXY = { top: '0px', left: '0px' };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible'] || changes['anchor']) {
      if (this.visible) {
        this.setPosition();
      }
    }
  }

  private setPosition() {
    this.anchorRect = this.anchor instanceof Element
                        ? this.anchor.getBoundingClientRect()
                        : this.anchor.nativeElement.getBoundingClientRect();
    this.positionXY.top = '-1000px';
    this.positionXY.left = '0px';

    // provo tre volte perchè l'elemento potrebbe non essere ancora presente nel DOM
    setTimeout(() => {
      if (!this.setPosition2()) {
        setTimeout(() => {
          if (!this.setPosition2()) {
            setTimeout(() => {
              if (!this.setPosition2()) {
                this.visible = false;
              }
            }, 100);
          }
        }, 50);
      }
    }, 50);

  }

  private setPosition2(): boolean {
    if (this.content) {
      this.contentRect = this.content.nativeElement.getBoundingClientRect();
      this.positionXY.top = `${this.anchorRect.bottom}px`;
      if (this.position == 'left') {
        this.positionXY.left = `${this.anchorRect.right - this.contentRect.width}px`;
      } else {
        this.positionXY.left = `${this.anchorRect.left}px`;
      }

      setTimeout(() => {
        // dopo un po' leggo la nuova posizione dell'elemento (nel frattempo potrebbe essere scomparso)
        this.contentRect = this.content
                         ? this.content.nativeElement.getBoundingClientRect()
                         : undefined;
      }, 100);

      return true;
    }

    return false;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {   // nasconde il content se mi allontano con il mouse
    if (   this.visible
        && this.autoClose
        && this.contentRect != undefined
        && (   e.clientX < this.contentRect.left - 40
            || e.clientX > this.contentRect.right + 40
            || e.clientY < this.contentRect.top - 40
            || e.clientY > this.contentRect.bottom + 40)) {

      this.visible = false;
      this.visibleChange.emit(false);
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(e: MouseEvent) {   // chiudo se clicco altrove, ma non su anchor
    if (   this.visible
        && this.contentRect != undefined
        && (   e.clientX < this.contentRect.left
            || e.clientX > this.contentRect.right
            || e.clientY < this.contentRect.top
            || e.clientY > this.contentRect.bottom)
        && (   e.clientX < this.anchorRect.left
            || e.clientX > this.anchorRect.right
            || e.clientY < this.anchorRect.top
            || e.clientY > this.anchorRect.bottom)) {

      this.visible = false;
      this.visibleChange.emit(false);
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      this.visible = false;
      this.visibleChange.emit(false);
    }
  }
}
