<div class="relative m-1 text-base text-gray-900">
  <label class="block ml-3 font-medium text-gray-600">{{label}}</label>

  <input type="text" #input
    readonly
    class="block w-full px-3 py-1.5
          border rounded-lg border-gray-300
          focus:outline-none focus:border-yellow-300 focus:ring-2 focus:ring-yellow-200"
    [placeholder]="placeholder"
    [ngModel]="selectedValue"
    (click)="onInputClick()"
    (focus)="onInputFocus()"
    (blur)="onInputBlur()">

  <div class="absolute bg-white top-6 right-0 mx-1 my-1.5 text-gray-500" (click)="onInputClick()">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
    </svg>
  </div>

  @if (showContent) {
    <div #content
      class="absolute z-20 right-0 inline-block mt-1 py-2 w-full bg-white border border-gray-300 rounded-lg shadow-md"
      (click)="onClick()">

      <div class="flex flex-col">

        @if (cancellable && selectedValue) {
          <div
            class="flex mx-2 px-3 cursor-pointer rounded whitespace-nowrap overflow-hidden hover:text-gray-900 hover:bg-gray-100"
            (click)="onCancelClick()">

            (Empty)
          </div>
        }

        @for (item of items; track $index) {
          <div
            class="flex mx-2 px-3 cursor-pointer rounded whitespace-nowrap overflow-hidden hover:text-gray-900 hover:bg-gray-100"
            (click)="onItemClick(item.key)">

            {{item.value}}
          </div>
        }
      </div>

    </div>
  }
</div>
