import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type Message = {
  type: 'MessageWaiting',
  event: 'Start' | 'End'
} | {
  type: 'MessageAlert',
  text: string
} | {
  type: 'MessageConfirm',
  title: string,
  text: string,
  buttons?: [string, ((() => void) | null)][]
};


@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private message: Subject<Message> = new Subject<Message>();
  public messages = this.message.asObservable();

  public waiting() {
    this.message.next({type: 'MessageWaiting', event: 'Start'});
  }

  public endWaiting() {
    this.message.next({type: 'MessageWaiting', event: 'End'});
  }

  public alert(text: string) {
    this.message.next({type: 'MessageAlert', text});
  }

  public confirm(title: string, text: string, buttons?: [string, ((() => void) | null)][]) {
    this.message.next({type: 'MessageConfirm', title, text, buttons});
  }
}
