@if (visible) {
  <div #content
    class="fixed z-20 inline-block mt-1 py-2 bg-white border border-gray-300 rounded-lg shadow-md"
    [ngStyle]="{ 'top': positionXY.top, 'left': positionXY.left }">

    <div class="pl-2 pr-1 py-1 overflow-x-auto min-w-24 max-h-72">
      <ng-content></ng-content>
    </div>

  </div>
}
