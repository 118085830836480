import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './input.component.html',
  styles: ``
})
export class InputComponent implements OnInit, OnChanges {
  @Input() label: string = '';
  @Input() pattern?: string;
  @Input() placeholder: string = '';
  @Input() value: string = '';
  @Output() enter = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('input') input!: ElementRef;

  formControl = new FormControl('');
  regExp?: RegExp;

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe(value => {
      if (value != null) {
        if (this.regExp && !value.match(this.regExp)) {
          this.formControl.setValue(this.value, { emitEvent: false });
        } else {
          this.value = value;
          this.valueChange.emit(this.value);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pattern'] && this.pattern != undefined) {
      this.regExp = new RegExp(this.pattern);
    }
    if (changes['value']) {
      this.formControl.setValue(this.value);
    }
  }

  onEnter() {
    this.enter.emit();
  }

  public setFocus() {
    this.input.nativeElement.focus();
  }

}
