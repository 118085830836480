
  <app-dropdown
    position="right"
    class="w-32"
    [anchor]="anchor!"
    [autoClose]="false"
    [visible]="visible"
    (visibleChange)="onVisibleChange($event)">

    <div class="flex flex-col items-center">
      <app-input #input
        [label]="type == 'string' ? 'Contains' : 'Equal'"
        [(value)]="value"
        (enter)="onEnter()">
      </app-input>

      <div class="flex mt-3">
        <app-button
          size="small"
          shape="outline"
          (click)="onClear()">
          Clear
        </app-button>
        <app-button
          size="small"
          (click)="onEnter()">
          Ok
        </app-button>
      </div>
    </div>
  </app-dropdown>
