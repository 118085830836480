import { Injector } from "@angular/core";
import { DataService } from "../../services/data/data.service";
import { I18nService } from "../../services/i18n.service";
import { MessageService } from "../../services/message/message.service";
import { NavigationService } from "../../services/navigation.service";
import { AuthService } from "../../services/auth.service";
import { ColumnDef } from "../../shared/components/table/table.component";
import { FilterDef } from "../../services/data/data.model";

export abstract class BasePage {
  protected authService: AuthService;
  protected dataService: DataService;
  protected i18n: I18nService;
  protected messageService: MessageService;
  protected navigationService: NavigationService;

  private name: string;
  protected abstract isReadOnly: boolean;

  constructor(injector: Injector, name: string) {
    this.authService = injector.get(AuthService);
    this.dataService = injector.get(DataService);
    this.i18n = injector.get(I18nService);
    this.messageService = injector.get(MessageService);
    this.navigationService = injector.get(NavigationService);

    this.name = name;
  }

  saveColumnStatus(columnDefs: ColumnDef[]) {
    this.dataService.setItem(`${this.name}.hiddenCols`,
      columnDefs.map((x,i) => {
        if (x.visible != undefined && !x.visible) {
          return i;
        } else {
          return null;
        }
      }).filter(x => x != null));
  }

  getColumnStatus(columnDefs: ColumnDef[]) {
    const cols = this.dataService.getItem<number[]>(`${this.name}.hiddenCols`);
    if (cols) {
      cols.forEach(x => { if (x < columnDefs.length) columnDefs[x].visible = false });
    }
  }

  protected getTableFilters(tableFilters: string[], columnDefs: ColumnDef[]): FilterDef[] {
    return tableFilters.map((x,i) => [
      columnDefs[i].field,
      (columnDefs[i].type == undefined || columnDefs[i].type == 'string') ? 'Like' : 'Equal',
      columnDefs[i].type == 'Date' && x 
        ? this.i18n.toISOString(this.i18n.parseDate(x)!) 
        : columnDefs[i].type == 'boolean' && x
          ? this.parseBoolean(x, columnDefs[i].format)
          : x
    ] as FilterDef)
      .filter(x => x[2]);
  }

  private parseBoolean(value: string, format: string | undefined): string {
    const f = format ? format.split('|') : ['yes', 'no'];
    return f[0].toLowerCase().includes(value.toLowerCase()) ? 'yes' : 'no';
  }
}
