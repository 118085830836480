<div class="flex flex-col w-full h-full">
  <div class="flex-shrink flex flex-row justify-between pt-2">
    <div class="flex flex-row flex-wrap items-end">
      <app-input-date
        class="w-44"
        label="Disemb. Date From"
        [toDate]="toDate"
        [(value)]="fromDate">
      </app-input-date>
      <app-input-date
        class="w-44"
        label="Disemb. Date To"
        [fromDate]="fromDate"
        [(value)]="toDate" >
      </app-input-date>
      <app-select label="Score Status" [items]="scoreStatus" [(selectedKey)]="selectedScoreStatus" class="w-40"></app-select>
      <app-button (click)="onSearchClick()">Search</app-button>
    </div>

    <div class="flex flex-row items-end">
      <!-- <app-select label="Operation Status" [items]="operationStatus" [(selectedKey)]="selectedOperationStatus" class="w-52"></app-select> -->
      <!-- <app-button (click)="onApplyClick()" [disabled]="!selectedOperationStatus || numChecked == 0">Apply</app-button> -->
      <app-button class="ms-6" (click)="onSendToVCClick()" [disabled]="numChecked == 0">Send To VC</app-button>
      <app-menu [items]="menuItems" (itemClick)="onMenuItemClick($event)"></app-menu>
    </div>
  </div>

  <div class="flex-auto w-full overflow-hidden">
    <app-table #table
      [columnDefs]="columnDefs"
      [columnPicker]="true"
      [statusBar]="true"
      [fetchData]="fetchData"
      idField="id"
      [checkedIds]="isReadOnly ? undefined : checkedIds"
      [checkable]="tableRowCheckable"
      [filters]="tableFilters"
      [sortColumns]="sortColumns"
      (checksChange)="onTableChecksChange()"
      (filterChange)="onTableFilterChange()"
      (layoutChange)="onTableLayoutChange()"
      (sortChange)="onTableSortChange()"
      (cellClick)="onTableRowClick($event)">
    </app-table>
  </div>
</div>

<app-pax-popup
  [id]="paxPopup.id"
  [name]="paxPopup.name"
  [surname]="paxPopup.surname"
  [(visible)]="paxPopup.visible"
  (dataChange)="onPaxPopupDataChange()">
</app-pax-popup>
