import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './list.component.html',
  styles: ``
})
export class ListComponent {
  items: ({key: string, value: string } | null)[] = [
    ['1', 'uno'],
    ['2', 'due'],
    null,
    ['3', 'tre']].map(x => (x == null ? null : { key: x[0], value: x[1] }));

  @Input() selectedKey: string | undefined;
  @Input() visible: boolean = false;
  @Output() selectedKeyChange = new EventEmitter<string>();

  onClick(key: string) {
    this.selectedKey = key;
    this.selectedKeyChange.emit(key);
  }
}
