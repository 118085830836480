<div class="flex justify-center">
  <div class="grid gap-8 p-28" [ngStyle]="{ 'grid-template-columns': 'repeat(' + numKpiEnabled + ',minmax(0,20rem))' }">

    @if(data) {

      @if(kpiEnabled[0]) {
        <div class="bg-white rounded-lg border-t-8 border-blue-800 shadow-md cursor-pointer" (click)="onClick(0)"
          [ngClass]="{'cursor-pointer': routeEnabled[0]}">

          <div class="flex flex-col justify-between p-8 text-center h-full">
            <h2 class="text-lg font-semibold text-gray-500 mb-4">PEP added in the last 7 days</h2>
            <p class="text-4xl font-bold text-gray-800">{{data.pepCount}}</p>
          </div>
        </div>
      }

      @if(kpiEnabled[1]) {
        <div class="bg-white rounded-lg border-t-8 border-blue-800 shadow-md" (click)="onClick(1)"
          [ngClass]="{'cursor-pointer': routeEnabled[1]}">

          <div class="flex flex-col justify-between p-8 text-center h-full">
            <h2 class="text-lg font-semibold text-gray-500 mb-4">Pax exceeded the risk threshold and not sent to UIF</h2>
            <p class="text-4xl font-bold text-gray-800">{{data.pepNotSentCount}}</p>
          </div>
        </div>
      }

      @if(kpiEnabled[2]) {
        <div class="bg-white rounded-lg border-t-8 border-blue-800 shadow-md" (click)="onClick(2)"
          [ngClass]="{'cursor-pointer': routeEnabled[2]}">

          <div class="flex flex-col justify-between p-8 text-center h-full">
            <h2 class="text-lg font-semibold text-gray-500 mb-4">Due Diligence Pax</h2>
            <p class="text-4xl font-bold text-gray-800">{{data.dueDiligenceCount}}</p>
          </div>
        </div>
      }
    }

  </div>
</div>
