<div class="w-full h-full">

  <div class="flex flex-row justify-end w-full h-[3rem]">
    <app-menu [items]="menuItems" (itemClick)="onMenuItemClick($event)"></app-menu>
  </div>

  <div class="flex justify-center w-full h-[calc(100%-3rem)] overflow-hidden">
    @if (isReadOnly) {
      <app-table
        class="max-w-full max-h-full"
        [columnDefs]="columnDefs"
        [statusBar]="true"
        [data]="data">
      </app-table>
    } @else {
      <app-table #table
        class="max-w-full max-h-full"
        [columnDefs]="columnDefs"
        [statusBar]="true"
        [data]="data"
        idField="id"
        (cellClick)="onTableRowClick($event)">
      </app-table>
    }
  </div>
</div>

<app-dialog
  [title]="edit.country"
  [buttons]="['Cancel', 'Ok']"
  [(visible)]="edit.visible"
  (buttonClick)="onEditClick($event)">

  <!-- <app-input
    label="High Risk"
    [(value)]="edit.isHighRisk">
  </app-input> -->

  <div class="flex justify-center mt-4 mb-6">
    <div class="mr-4">High Risk Country</div>
    <div class="relative text-gray-500 font-medium">
      <input class="sr-only peer" type="radio" name="hrc" id="hrc_no" value="No" [(ngModel)]="edit.isHighRisk">
      <label class="pr-3 pl-4 pt-1 pb-2 border border-gray-300 rounded-l-full cursor-pointer
        hover:bg-gray-50
        peer-checked:bg-green-400 peer-checked:text-white peer-checked:border-green-400" for="hrc_no">No</label>
    </div>
    <div class="relative text-gray-500 font-medium">
      <input class="sr-only peer" type="radio" name="hrc" id="hrc_yes" value="Yes" [(ngModel)]="edit.isHighRisk">
      <label class="pr-4 pl-3 pt-1 pb-2 border border-gray-300 rounded-r-full cursor-pointer hover:bg-gray-50
      peer-checked:bg-red-400 peer-checked:text-white peer-checked:border-red-400" for="hrc_yes">Yes</label>
    </div>
  </div>

</app-dialog>
