<div class="p-1" (click)="disabled && $event.stopPropagation()">
  <button
    [disabled]="disabled"
    type="button"
    class="text-center font-medium
           border-2 border-yellow-400 rounded-lg
           outline-offset-4 outline-yellow-200
           disabled:bg-gray-300 disabled:border-gray-200"
    [ngClass]="{'text-sm px-2 pt-0.5 pb-1 min-w-14 h-7': size == 'small',
                'text-base px-2 pt-1 pb-1.5 min-w-20': size == 'normal',
                'bg-yellow-400 hover:bg-yellow-300 text-gray-600': shape == 'standard',
                'text-yellow-400 hover:bg-yellow-50': shape == 'outline'}">

    <ng-content></ng-content>
  </button>
</div>
