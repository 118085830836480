import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MainModule } from '../../main.module';
import { BasePage } from '../base-page';
import { ColumnDef, TableComponent } from '../../../shared/components/table/table.component';
import { PaxDrillDown, FilterDef } from '../../../services/data/data.model';
import { CommonModule } from '@angular/common';
import { PaxPopupComponent } from '../pax-popup/pax-popup.component';
import { Observable, map } from 'rxjs';
import { appFunctions } from '../../../app.model';

@Component({
  selector: 'app-due-diligence-register',
  standalone: true,
  imports: [CommonModule, MainModule, PaxPopupComponent],
  templateUrl: './due-diligence-register.component.html',
  styles: ``
})
export class DueDiligenceRegisterComponent extends BasePage implements OnInit {
  constructor(private injector: Injector) { super(injector, 'due-diligence-register'); }

  @ViewChild('table') table!: TableComponent;
  isReadOnly = true;

  paxPopup = {id: '', name: '', surname: '', visible: false};

  mainFilters!: {
    fromDate: Date,
    toDate: Date,
    name: string,
    surname: string,
    taxId: string,
    brand: string
  };
  tableFilters: string[] = [];
  private allFilters: FilterDef[] = [];

  menuItems = [
    { key: '1', value: 'Transactions Report', icon: 'document-text',
      disabled: !this.authService.isEnabled(appFunctions.DueDiligence_Report) },
  ];

  columnDefs: ColumnDef[] = [
    { field: 'brand', header: 'Brand', noFilter: true },
    { field: 'shipCode', header: 'Ship' },
    { field: 'cruiseDate', header: 'Cruise Date', type: 'Date' },
    { field: 'paxId', header: 'Pax ID' },
    { field: 'name', header: 'Name', noFilter: true },
    { field: 'surname', header: 'Surname', noFilter: true },
    { field: 'gender', header: 'Gender' },
    { field: 'score', header: 'Score', type: 'number' },
    { field: 'scoreStatus', header: 'Score Status' },
    { field: 'placeOfBirth', header: 'POB' },
    { field: 'dateOfBirth', header: 'DOB', type: 'Date' },
    { field: 'residenceCountry', header: 'Country of Residence' },
    { field: 'nationality', header: 'Nationality' },
    { field: 'isPep', header: 'Is PEP', type: 'boolean' },
    { field: 'taxId', header: 'Tax ID', noFilter: true },
    { field: 'embDate', header: 'Embark Date', type: 'Date' },
    { field: 'disDate', header: 'Disembark Date', noFilter: true },
    { field: 'bookingNumber', header: 'Booking No.' },
    { field: 'cruiseItineraryId', header: 'Itinerary ID' },
    { field: 'documentType', header: 'Doc. Type' },
    { field: 'documentNumber', header: 'Doc. Number' },
    { field: 'documentIssueDate', header: 'Doc. Issue Date', type: 'Date' },
    { field: 'documentIssuePlace', header: 'Doc. Issue Place' },
    { field: 'documentExpiryDate', header: 'Doc. Expiry Date', type: 'Date' },
    { field: 'dueDiligence', header: 'Due Diligence', type: 'boolean' },
    { field: 'riskyBehaviors', header: 'Risky Behaviors', type: 'boolean' },
    { field: 'riskyTransactions', header: 'Risky Transactions', type: 'boolean' },
    { field: 'riskyCountry', header: 'High Risk Country', type: 'boolean' },
    { field: 'operationStatus', header: 'Operations Status' },
    { field: 'bancaItaliaSentDate', header: 'Operation Date', type: 'Date' },
    { field: 'operationUserID', header: 'User ID' },
    { field: 'notes', header: 'Note' },
  ];
  sortColumns = ['surname', 'name'];


  ngOnInit(): void {
    this.mainFilters = {
      fromDate: this.i18n.today(-28),
      toDate: this.i18n.today(28),
      name: '',
      surname: '',
      taxId: '',
      brand: ''
    };

    this.setFilters();
    this.getData();
    this.getColumnStatus(this.columnDefs);
  }

  private getData() {
    this.messageService.waiting();

    this.dataService.getDueDiligence(1, this.sortColumns, this.allFilters).subscribe(x => {
      this.table.initData(x[0], x[1]);
      this.messageService.endWaiting();
    });
  }

  protected fetchData = (page: number): Observable<object[]> => {
    return this.dataService.getDueDiligence(page, this.sortColumns, this.allFilters).pipe(
      map(x => x[0])
    );
  }

  onButtonSearch() {
    this.setFilters();
    this.getData();
  }

  onTableLayoutChange() {
    this.saveColumnStatus(this.columnDefs);
  }

  onTableFilterChange() {
    this.setFilters();
    this.getData();
  }

  onTableSortChange() {
    if (this.sortColumns.length == 0) {
      this.sortColumns.push('surname');
      this.sortColumns.push('name');
    }
    this.getData();
  }

  onTableRowClick([row, cellIndex]: [object, number]) {
    const data = row as PaxDrillDown;
    this.paxPopup.id = data.id;
    this.paxPopup.name = data.name;
    this.paxPopup.surname = data.surname;
    this.paxPopup.visible = true;
  }

  onPaxPopupDataChange() {
    //TODO this.table.refresh();
  }

  onMenuChoice(itemKey: string) {
    this.messageService.waiting();

    if (itemKey == '1') {
      this.dataService.getDueDiligencePDF([], this.allFilters).subscribe(x => {
        this.messageService.endWaiting();
        this.dataService.saveAs(x.file, (x.filename ? x.filename : 'DueDiligence_Report.pdf'));
      });
    }
  }

  private setFilters() {
    this.allFilters = [
      ['disDate', 'GreaterEqual', this.mainFilters.fromDate],
      ['disDate', 'LessEqual', this.mainFilters.toDate],
      ...this.getTableFilters(this.tableFilters, this.columnDefs)
    ];
    if (this.mainFilters.name) {
      this.allFilters.push(['name', 'Like', this.mainFilters.name]);
    }
    if (this.mainFilters.surname) {
      this.allFilters.push(['surname', 'Like', this.mainFilters.surname]);
    }
    if (this.mainFilters.taxId) {
      this.allFilters.push(['taxId', 'Equal', this.mainFilters.taxId]);
    }
    if (this.mainFilters.brand) {
      this.allFilters.push(['brand', 'Like', this.mainFilters.brand]);
    }
  }

}
