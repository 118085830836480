import { Component, Injector, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { MessageOutletComponent } from '../../../services/message/message-outlet.component';
import { SharedModule } from '../../../shared/shared.module';
import { BasePage } from '../base-page';
import { appFunctions } from '../../../app.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MessageOutletComponent, SharedModule],
  templateUrl: './home.component.html',
  styles: [],
})
export class HomeComponent extends BasePage implements OnInit {
  constructor(private injector: Injector) { super(injector, 'home'); }

  accountName?: string;
  currentPage = { label: '', route: '' };
  isReadOnly = false;

  menu = [
    { section: 'Dashboards', icon: 'chart-bar-square', pages: [
        { label: 'Overall', route: 'overall', function: appFunctions.Dashboard_GetCounts },
      ]
    },
    { section: 'Actions', icon: 'rocket-launch', pages: [
        { label: 'PEP Approval', route: 'pep-approval', function: appFunctions.PEPApprovals_Search },
        { label: 'Suspicious Transactions', route: 'suspicious-trx', function: appFunctions.SuspiciousTransactions_Search },
      ]
    },
    { section: 'Data Analysis', icon: 'beaker', pages: [
         { label: 'Pax Drilldown', route: 'pax-drilldown', function: appFunctions.Paxes_Search },
         { label: 'Due Diligence Register', route: 'due-diligence-register', function: appFunctions.DueDiligence_Search }
      ]
    },
    { section: 'Settings', icon: 'settings', pages: [
        { label: 'Due Diligence Thresholds', route: 'settings/due-diligence', function: appFunctions.Settings_DueDiligence },
        { label: 'High Risk Countries', route: 'settings/countries', function: appFunctions.Countries_Search },
        { label: 'Risk Scores', route: 'settings/risk-scores', function: appFunctions.Settings_RiskScore },
      ]
    },
  ];


  ngOnInit(): void {
    this.authService.isReady.subscribe(isReady => {
      if (isReady) {
        this.navigationService.routeChange.subscribe(route => {
          this.currentPage = this.menu.flatMap(x => x.pages).find(x => x.route == route)!;
        });

        this.accountName = this.authService.getAccountName();

        for (let page of this.menu.flatMap(x => x.pages)) {
          if (this.authService.isEnabled(page.function)) {
            this.currentPage = page;
            this.navigationService.go(page.route);  // va alla prima pagina abilitata
            break;
          }
        }
      }
    });
  }

  protected onMenuClick(page: { label: string, route: string }) {
    this.navigationService.go(page.route);
  }

}
