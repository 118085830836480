import { CommonModule, formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-input-date',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './input-date.component.html',
  styles: ``
})
export class InputDateComponent implements OnChanges {
  @Input() fromDate: Date | null = null;
  @Input() toDate: Date | null = null;
  @Input() value: Date | null = null;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Output() valueChange = new EventEmitter<Date>();

  @ViewChild('input') input!: ElementRef;

  MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  DAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  selectedDate = { day: 0, month: 0, year: 0, formatted: '' };

  showPicker = false;
  picker = {
    month: 0,
    year: 0,
    monthDays: [] as number[],
    blankDays: [] as number[]
  }

  from = { day: 1, month: 0, year: 2000};
  to = { day: 31, month: 11, year: 2050};

  keepFocus: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fromDate']) {
      this.from.day = this.fromDate ? this.fromDate.getDate() : 1;
      this.from.month = this.fromDate ? this.fromDate.getMonth() : 0;
      this.from.year = this.fromDate ? this.fromDate.getFullYear() : 2000;
    }
    if (changes['toDate']) {
      this.to.day = this.toDate ? this.toDate.getDate() : 31;
      this.to.month = this.toDate ? this.toDate.getMonth() : 11;
      this.to.year = this.toDate ? this.toDate.getFullYear() : 2050;
    }
    if (changes['value']) {
      if (this.value) {
        this.selectedDate.day = this.value.getDate();
        this.selectedDate.month = this.value.getMonth();
        this.selectedDate.year = this.value.getFullYear();
        this.selectedDate.formatted = formatDate(this.value, 'dd/MM/yyyy', 'en');
      } else {
        this.selectedDate = { day: 0, month: 0, year: 0, formatted: '' };
      }
    }
  }

  onInputClick() {
    if (!this.disabled) {
      this.onClick();
      this.showPicker = !this.showPicker;
      if (this.showPicker) {
        if (this.value == undefined) {
          this.value = new Date();
        }
        this.picker.month = this.value.getMonth();
        this.picker.year = this.value.getFullYear();
        this.selectedDate.formatted = formatDate(this.value, 'dd/MM/yyyy', 'en');
        this.calcMonthDays();
      }
  }
}

  isSelectedDate(day: number): boolean {
    return this.picker.year == this.selectedDate.year
        && this.picker.month == this.selectedDate.month
        && day == this.selectedDate.day;
  }

  isDateInRange(day: number): boolean {
    return ! ((this.picker.year < this.from.year || this.picker.year > this.to.year)
           || (this.picker.year == this.from.year && this.picker.month < this.from.month)
           || (this.picker.year == this.to.year && this.picker.month > this.to.month)
           || (this.picker.year == this.from.year && this.picker.month == this.from.month && day < this.from.day)
           || (this.picker.year == this.to.year && this.picker.month == this.to.month && day > this.to.day));
  }

  calcMonthDays() {
    const daysInMonth = new Date(this.picker.year, this.picker.month + 1, 0).getDate();
    const dayOfWeek = new Date(this.picker.year, this.picker.month).getDay();

    this.picker.blankDays = [];
    for (let i=1; i <= dayOfWeek; i++) {
      this.picker.blankDays.push(i);
    }

    this.picker.monthDays = [];
    for (let i=1; i <= daysInMonth; i++) {
      this.picker.monthDays.push(i);
    }
  }

  onInputFocus() {
    this.keepFocus = true;
  }

  onInputBlur() {
    this.keepFocus = false;
    setTimeout(() => {
      if (!this.keepFocus) {
        this.showPicker = false;
      }
    }, 200);
  }

  onClick() {
    this.keepFocus = true;
    this.input.nativeElement.focus();  // rimetto il focus su casella input
  }

  onDayClick(day: number) {
    this.onClick();

    this.value = new Date(this.picker.year, this.picker.month, day, 0, 0, 0);
    this.selectedDate.formatted = formatDate(this.value, 'dd/MM/yyyy', 'en');
    this.valueChange.emit(this.value);
    this.showPicker = false;
  }

  onPrevMonthClick(enabled: boolean) {
    if (enabled) {
      if (this.picker.month > 0) {
        this.picker.month--;
      } else {
        this.picker.month = 11;
        this.picker.year--;
      }
      this.calcMonthDays();
    }
  }

  onNextMonthClick(enabled: boolean) {
    if (enabled) {
      if (this.picker.month < 11) {
        this.picker.month++;
      } else {
        this.picker.month = 0;
        this.picker.year++;
      }
      this.calcMonthDays();
    }
  }
}
