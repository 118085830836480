<div class="p-1 inline-flex flex-col max-h-full max-w-full">
  <div #scrollArea
    class="overflow-auto rounded-t-lg border border-gray-300"
    [ngClass]="{'rounded-b-lg': !statusBar}">

    <table class="text-sm divide-y divide-gray-200">
      <thead #thead class="sticky top-0 z-[1] text-left">
        <tr class="text-gray-800 relative">
          @if (checkedIds) {
            <th class="sticky left-0 px-3 pt-2 whitespace-nowrap bg-slate-100 shadow-[inset_0_-1px] shadow-gray-300">
              <!-- <input class="h-4 w-4 accent-blue-900 outline-offset-0 outline-yellow-300"
                type="checkbox"
                [ngModel]="checkAll" (ngModelChange)="onCheckAllChange($event)"> -->
            </th>
          }

          @for (column of columnDefs; track $index) {
            @if (column.visible == undefined || column.visible) {
              <th class="px-3 py-1 min-w-24 font-medium bg-slate-100 shadow-[inset_0_-1px] shadow-gray-300"
                (mouseenter)="onHeaderHover($index, true)"
                (mouseleave)="onHeaderHover($index, false)">

                {{column.header}}
              </th>
            }
          }

          @if (columnPicker) {
            <th class="sticky right-0 px-1 bg-slate-100 shadow-[inset_0_-1px] shadow-gray-300">
              <div class="p-1 rounded-md bg-slate-200 border border-slate-300 hover:cursor-pointer" #columnPickerButton
                (click)="columnPickerMenu.visible = !columnPickerMenu.visible">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                </svg>
              </div>
            </th>
          }
        </tr>

        @if (has2ndHeader) {
          <tr class="text-gray-500 bg-slate-100 shadow-[inset_0_-1px] shadow-gray-300">

            @if (checkedIds) {
              <th class="sticky left-0"></th>
            }

            @for (column of columnDefs; track $index) {
              @if (column.visible == undefined || column.visible) {
                <th class="relative px-3 text-transparent hover:text-inherit">
                  <div class="flex gap-2">
                    @if (!column.noFilter) {
                      <div
                        [ngClass]="{'text-gray-500': filters[$index] || (currentFilter.index == $index && currentFilter.visible)}"
                        (click)="onFilterClick($index)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 hover:cursor-pointer">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                        </svg>
                      </div>
                    }
                    <div
                      class="flex"
                      [ngClass]="{'text-gray-500': sortColumns.includes(column.field) || sortColumns.includes('-' + column.field)}"
                      (click)="onSortChange($index, $event)">

                      @if (sortColumns.includes('-' + column.field)) {
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 hover:cursor-pointer">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25" />
                        </svg>
                      } @else {
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 hover:cursor-pointer">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
                        </svg>
                      }

                      @if (sortColumns.length > 1 && sortColumns.includes(column.field)) {
                        <div class="text-xs font-normal">{{sortColumns.indexOf(column.field) + 1}}</div>
                      }
                      @if (sortColumns.length > 1 && sortColumns.includes('-' + column.field)) {
                        <div class="text-xs font-normal">{{sortColumns.indexOf('-' + column.field) + 1}}</div>
                      }
                    </div>
                  </div>
                </th>
              }
            }

            @if (columnPicker) {
              <th class="sticky right-0"></th>
            }
          </tr>
        }
      </thead>


      <tbody class="bg-white divide-y divide-gray-200">
        @if (rows.length == 0) {
          <tr class="h-12 whitespace-nowrap">
            <td class="sticky left-[calc(50%-2rem)]"
              [colSpan]="1 + (checkedIds ? 1 : 0) + (columnPicker ? 1 : 0)" >
              No Data
            </td>
            <td [colSpan]="columnDefs.length - 1" ></td>
          </tr>
        }

        @for (row of rows; track rowIndex; let rowIndex = $index) {
          <tr class="text-gray-700"
            [ngClass]="{'hover:bg-gray-50': cellClick.observed}">

            @if (checkedIds) {
              <td class="sticky left-0 px-3 h-8 pt-1 bg-white"
                [ngClass]="{'[tr:hover>&]:bg-gray-50': cellClick.observed}">

                <input class="accent-blue-900 outline-offset-0 outline-yellow-300"
                  type="checkbox"
                  [disabled]="!row.checkable"
                  [ngModel]="row.checked"  (ngModelChange)="onCheckChange(rowIndex, $event)">
              </td>
            }

            @for (cell of row.cells; track $index) {
              @if (columnDefs[$index].visible == undefined || columnDefs[$index].visible) {
                <td class="px-3 h-8 text-sm max-w-[32rem] overflow-hidden text-ellipsis whitespace-nowrap"
                  [ngClass]="{'cursor-pointer': cellClick.observed}"
                  (click)="onClick(rowIndex, $index)">

                    {{cell}}
                </td>
              }
            }

            @if (columnPicker) {
              <td></td>
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
  @if (statusBar) {
    <div class="flex px-3 py-1 text-xs bg-slate-100 rounded-b-lg border border-gray-300 gap-4">
      @if (totalRows) {
        <div>{{totalRows}} row{{totalRows > 1 ? 's' : ''}}</div>
      }
      @if (checkedIds && checkedIds.length > 0) {
        <div>{{checkedIds.length}} selected</div>
      }
    </div>
  }
</div>


<app-table-filter
  [anchor]="currentFilter.anchor"
  [type]="currentFilter.type"
  [value]="currentFilter.value"
  (valueChange)="onFilterChange($event)"
  [(visible)]="currentFilter.visible">
</app-table-filter>


@if (columnPicker) {
  <app-dropdown [anchor]="columnPickerButton!" [(visible)]="columnPickerMenu.visible">
    <div class="flex flex-col font-normal text-gray-500 whitespace-nowrap">
      @for (column of columnDefs; track $index) {
          <div class="flex pl-2 pr-1 py-1">
            <input class="accent-blue-900 outline-offset-0 outline-yellow-300"
              id="cpm{{$index}}"
              type="checkbox"
              [ngModel]="columnDefs[$index].visible == undefined || columnDefs[$index].visible"
              (ngModelChange)="onColumnPickerItemChange($index, $event)">

            <label for="cpm{{$index}}" class="ml-2">{{column.header}}</label>
          </div>
      }
    </div>
  </app-dropdown>
}
