import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { InputComponent } from '../input/input.component';
import { ButtonComponent } from '../button/button.component';
import { TableDataType } from './table.component';
import { I18nService } from '../../../services/i18n.service';

@Component({
  selector: 'app-table-filter',
  standalone: true,
  imports: [ButtonComponent,DropdownComponent,InputComponent],
  templateUrl: './table-filter.component.html',
  styles: ``
})
export class TableFilterComponent implements OnChanges {
  constructor(private i18n: I18nService) { }

  @Input() anchor?: Element;
  @Input() type?: TableDataType = 'string';
  @Input() value: string = '';
  @Input() visible: boolean = false;
  @Output() valueChange = new EventEmitter<string>();
  @Output() visibleChange = new EventEmitter<boolean>();

  @ViewChild('input') input!: InputComponent;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible'] && this.visible) {
      setTimeout(() => {
          this.input.setFocus();
        }, 100);
    }
  }

  onEnter() {
    let value = this.value.trim();

    if (this.type == 'Date') {
      let d = this.i18n.parseDate(value);
      if (d == null) {
        this.valueChange.emit('');
      } else {
        this.valueChange.emit(value);
      }
    } else {
      this.valueChange.emit(value);
    }
  }

  onClear() {
    this.valueChange.emit('');
  }

  onVisibleChange(visible: boolean) {
    if (!visible) {
      this.value = '';
    }
    this.visible = visible;
    this.visibleChange.emit(visible);
  }

}
