import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private isoDateFormat = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}.*)?$/;
  //private tzOffset = - (new Date(Date.now())).getTimezoneOffset() * 60 * 1000;

  public isIsoDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return this.isoDateFormat.test(value);
    }
    return false;
  }

  public toISOString(date: Date): string {
    // formato iso senza orario e senza timezone
    return formatDate(date, 'yyyy-MM-dd', 'en') + 'T00:00:00';
  }

  // public toDate(ISODateString: string): Date {
  //   let d = new Date(ISODateString);
  //   d.setTime(d.getTime() + this.tzOffset);
  //   return d;
  // }

  public today(dayOffset: number = 0): Date {
    let d = new Date();
    d.setHours(0, 0, 0, 0)
    if (dayOffset) {
      d.setDate(d.getDate() + dayOffset);
    }
    return d;
  }

  parseDate(s: string): Date | null {
    let year = +s.substring(6, 10);
    let month = +s.substring(3, 5);
    let day = +s.substring(0, 2);

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return null;
    }

    let result = new Date(year, month-1, day, 0, 0, 0);
    if (formatDate(result, 'dd/MM/yyyy', 'en') != s) {
      return null;
    }

    return result;
  }
}


